import React, { useEffect, useState, useRef } from "react";
import puzzles from "../../assets/puzzleImages/index.js";
import { useWallet } from "../../hooks/useWallet";
import { useAuth } from "../../hooks/useAuth";
import { ethers } from "ethers";
import { getSignature } from "../../api/games";
import { toastPromise } from "../../services/util";
import { toast } from "react-toastify";
import axios from "axios";
import { apiCall } from "../../services/ApiCall";
import configs from "../../config/configs.json";
import RewardCard from "../../components/games/RewardCard.jsx";
import FilledButton from "../../components/games/FilledButton.jsx";
import LearnButton from "../../components/common/LearnButton.jsx";

const tiles1 = [
  [
    { image: puzzles.puzzle1[1], number: 1 },
    { image: puzzles.puzzle1[2], number: 2 },
    { image: puzzles.puzzle1[3], number: 3 },
  ],
  [
    { image: puzzles.puzzle1[4], number: 4 },
    { image: puzzles.puzzle1[5], number: 5 },
    { image: puzzles.puzzle1[6], number: 6 },
  ],
  [
    { image: puzzles.puzzle1[7], number: 7 },
    { image: puzzles.puzzle1[8], number: 8 },
    { image: puzzles.puzzle1[9], number: 0 },
  ],
];
const tiles2 = [
  [
    { image: puzzles.puzzle2[1], number: 1 },
    { image: puzzles.puzzle2[2], number: 2 },
    { image: puzzles.puzzle2[3], number: 3 },
  ],
  [
    { image: puzzles.puzzle2[4], number: 4 },
    { image: puzzles.puzzle2[5], number: 5 },
    { image: puzzles.puzzle2[6], number: 6 },
  ],
  [
    { image: puzzles.puzzle2[7], number: 7 },
    { image: puzzles.puzzle2[8], number: 8 },
    { image: puzzles.puzzle2[9], number: 0 },
  ],
];
const tiles3 = [
  [
    { image: puzzles.puzzle3[1], number: 1 },
    { image: puzzles.puzzle3[2], number: 2 },
    { image: puzzles.puzzle3[3], number: 3 },
  ],
  [
    { image: puzzles.puzzle3[4], number: 4 },
    { image: puzzles.puzzle3[5], number: 5 },
    { image: puzzles.puzzle3[6], number: 6 },
  ],
  [
    { image: puzzles.puzzle3[7], number: 7 },
    { image: puzzles.puzzle3[8], number: 8 },
    { image: puzzles.puzzle3[9], number: 0 },
  ],
];
const tiles4 = [
  [
    { image: puzzles.puzzle4[1], number: 1 },
    { image: puzzles.puzzle4[2], number: 2 },
    { image: puzzles.puzzle4[3], number: 3 },
  ],
  [
    { image: puzzles.puzzle4[4], number: 4 },
    { image: puzzles.puzzle4[5], number: 5 },
    { image: puzzles.puzzle4[6], number: 6 },
  ],
  [
    { image: puzzles.puzzle4[7], number: 7 },
    { image: puzzles.puzzle4[8], number: 8 },
    { image: puzzles.puzzle4[9], number: 0 },
  ],
];
const tiles5 = [
  [
    { image: puzzles.puzzle5[1], number: 1 },
    { image: puzzles.puzzle5[2], number: 2 },
    { image: puzzles.puzzle5[3], number: 3 },
  ],
  [
    { image: puzzles.puzzle5[4], number: 4 },
    { image: puzzles.puzzle5[5], number: 5 },
    { image: puzzles.puzzle5[6], number: 6 },
  ],
  [
    { image: puzzles.puzzle5[7], number: 7 },
    { image: puzzles.puzzle5[8], number: 8 },
    { image: puzzles.puzzle5[9], number: 0 },
  ],
];

const Tile = ({ data, move, gameStarted }) => {
  return (
    <button
      onClick={move}
      className={`${data.number === 0 ? "opacity-0" : ""} m-px`}
      disabled={!gameStarted}
    >
      <img
        src={data.image}
        className="hover:scale-[.92] transition-all duration-[200ms] ease-in-out hover:rounded-md"
      />
    </button>
  );
};

const PuzzleSlider = () => {
  const allTiles = [tiles1, tiles2, tiles3, tiles4, tiles5];
  const solution = [
    [1, 2, 3],
    [4, 5, 6],
    [7, 8, 0],
  ];
  const [plane, setPlane] = useState([]);
  const [image, setImage] = useState();
  const [showImage, setShowImage] = useState(true);
  const [blankIndex, setBlankIndex] = useState([2, 2]);
  const [gameStarted, setGameStarted] = useState(false);
  const [solved, setSolved] = useState(false);
  const [time, setTime] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [slidingLeft, setSlidingLeft] = useState(0);
  const [totalRewards, setTotalRewards] = useState(0);
  const [currentEarnings, setCurrentEarnings] = useState(0);
  const [played, setPlayed] = useState(0);
  const [originalrollsLeft, setOriginalrollsLeft] = useState(10);
  const wallet = useWallet();
  const auth = useAuth();
  const config = wallet.getChainConfig();
  const getActivityCount = async () => {
    try {
      const { isSuccess, data } = await apiCall(
        `${configs.POST_LOGIN_API_URL}lightLink/getActivityCount`,
        {},
        {
          gameType: "SLIDINGPUZZLE",
          date: new Date(),
        },
        "GET"
      );
      if (isSuccess) {
        const count = parseInt(data.data?.processCount.toString(), 10);
        setSlidingLeft(10 - count);
        setOriginalrollsLeft(10 - count);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const mintReward = async () => {
    try {
      setIsSubmitting(true);
      const { isSuccess, data } = await apiCall(
        `${configs.POST_LOGIN_API_URL}mint/erc20`,
        {},
        {
          amount: String(amount),
        },
        "POST"
      );
      setIsSubmitting(false);

      if (isSuccess) {
        // updateRewardCount(amount);
        // updateActivityCount();
        wallet.updatePoints({
          amount: amount,
          chainId: wallet.chainId,
          walletAddress: wallet.address,
          moduleName: "slidingpuzzle",
          txHash: "lightlink",
        });

        setTotalRewards(+totalRewards + +ethers.utils.formatEther(amount));
        setCurrentEarnings(0);
        setPlayed(0);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const updateRewardCount = async (reward) => {
    const res = await axios.get("/api/nft/all-balance");
    let multiplier = 1;
    if (res && res.multiplier > 1) {
      multiplier = res.multiplier;
    }
    const amount = Number(currentEarnings) * multiplier;
    try {
      const { isSuccess, data } = await apiCall(
        `${configs.POST_LOGIN_API_URL}lightLinkReward/updateRewardCount`,
        {},
        {
          reward: amount,
          gameType: "SLIDINGPUZZLE",
          date: new Date(),
          count: originalrollsLeft - slidingLeft,
        },
        "POST"
      );
      if (isSuccess) {
        await mintReward(amount);
        getRewardCount();
        auth.updateTokens();
      } else {
        getActivityCount();
        setCurrentEarnings(0);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getRewardCount = async () => {
    try {
      const { isSuccess, data } = await apiCall(
        `${configs.POST_LOGIN_API_URL}lightLinkReward/getRewardCount`,
        {},
        { gameType: "SLIDINGPUZZLE" },
        "GET"
      );
      if (isSuccess) {
        setTotalRewards(data.data?.rewardCount);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const updateActivityCount = async () => {
    const currentCount = await getActivityCount();
    try {
      const { isSuccess, data } = await apiCall(
        `${configs.POST_LOGIN_API_URL}lightLink/updateActivityCount`,
        {},
        {
          gameType: "SLIDINGPUZZLE",
          date: new Date(),
          count: currentCount + 1,
        },
        "POST"
      );
      if (isSuccess) {
      }
    } catch (error) {
      console.error(error);
    }
  };

  const init = async () => {
    setIsLoading(true);

    setCurrentEarnings(0);
    if (wallet.chainId === 1890 || wallet.chainId === 1891) {
      getActivityCount();
    } else {
      const [, left] = await wallet.safeCallContract({
        name: "Game",
        method: "getSlidesLeft",
        args: [wallet.address],
      });

      if (left) {
        const count = parseInt(left.toString(), 10);
        setSlidingLeft(count);
      }

      const [, rewards] = await wallet.safeCallContract({
        name: "Game",
        method: "getSlidingRewards",
        args: [wallet.address],
      });

      if (rewards) {
        console.log(rewards);
        setTotalRewards(
          Number(ethers.utils.formatUnits(rewards.toString(), 18)).toFixed(0)
        );
      }

      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!wallet.address) return;

    init();
  }, [wallet.address, wallet.chainId]);

  useEffect(() => {
    setImage(puzzles.puzzle1[0]);
    setPlane(allTiles[0]);
    if (wallet.chainId === 1890 || wallet.chainId === 1891) {
      getActivityCount();
      getRewardCount();
    }
  }, []);

  useEffect(() => {
    if (solved) {
      setCurrentEarnings(currentEarnings + calculateReward());
      setSlidingLeft(slidingLeft - 1);
      setGameStarted(false);
    }
  }, [solved]);

  useEffect(() => {
    setTimeout(() => {
      if (gameStarted) {
        setTime(time + 1);
      }
    }, 1000);
  });

  const handleBulkSlidingSubmit = async () => {
    if (gameStarted) return;
    console.log("bulk submit hit");
    console.log(played, currentEarnings);
    if (!played || currentEarnings === 0) {
      return toast.info("You must play at lease once");
    }

    setIsSubmitting(true);
    if (wallet.chainId === 1890 || wallet.chainId === 1891) {
      // mintReward();
      updateRewardCount();
    } else {
      try {
        const res = await axios.get("/api/nft/all-balance");
        let multiplier = 1;
        if (res && res?.multiplier > 1) {
          multiplier = res?.multiplier;
        }
        const amount = Number(currentEarnings) * multiplier;
        const message = "Sliding Puzzle";
        const nonce = 789;

        const signature = await getSignature({
          to: wallet.address,
          amount: ethers.utils.parseEther(amount.toString()),
          message: message,
          nonce: nonce,
          chainId: wallet.chainId,
        });

        const [err, tx] = await wallet.safeCallContract({
          name: "Game",
          method: "bulkSliding",
          args: [
            played,
            ethers.utils.parseEther(amount.toString()),
            1,
            message,
            nonce,
            signature,
            {
              value: ethers.utils.parseEther(
                String((config.features.spinValue * played).toFixed(8))
              ),
            },
          ],
        });

        if (tx) {
          await toastPromise(tx.wait(), {
            pending: "Processing...",
            success: () => {
              wallet.updatePoints({
                amount: amount,
                chainId: wallet.chainId,
                walletAddress: wallet.address,
                moduleName: "slidingpuzzle",
                txHash: tx.hash,
              });
              console.log();
              console.log(totalRewards);
              console.log(+totalRewards + +ethers.utils.formatEther(amount));
              setTotalRewards(
                +totalRewards + +ethers.utils.formatEther(amount)
              );
              setCurrentEarnings(0);
              setPlayed(0);
              auth.updateTokens();
              return "Sliding Puzzle submitted successfully";
            },
            error: () => {
              return "Submit Failed";
            },
          });
        }
      } catch (error) {
        console.log(error);
      }

      setIsSubmitting(false);
    }
  };

  const calculateReward = () => {
    const reward = 100 - time;
    if (reward > 10) {
      return reward;
    } else return 10;
  };

  const isValidMove = (r, c, blank) => {
    const validMoves = getValidMoves(blank);
    let found = 0;

    validMoves.forEach((e) => {
      if (e[0] === r && e[1] === c) {
        found = 1;
      }
    });
    if (found === 1) {
      return true;
    } else {
      return false;
    }
  };

  const moveTile = (r, c, tempPlane, blank) => {
    if (isValidMove(r, c, blank)) {
      let temp = tempPlane[r][c];
      tempPlane[r][c] = tempPlane[blank[0]][blank[1]];
      tempPlane[blank[0]][blank[1]] = temp;
      return tempPlane;
    }
    return tempPlane;
  };

  const getValidMoves = (_blankIndex) => {
    let validMoves = [];
    if (_blankIndex[0] < 2) {
      validMoves.push([_blankIndex[0] + 1, _blankIndex[1]]);
    }
    if (_blankIndex[0] > 0) {
      validMoves.push([_blankIndex[0] - 1, _blankIndex[1]]);
    }
    if (_blankIndex[1] > 0) {
      validMoves.push([_blankIndex[0], _blankIndex[1] - 1]);
    }
    if (_blankIndex[1] < 2) {
      validMoves.push([_blankIndex[0], _blankIndex[1] + 1]);
    }
    return validMoves;
  };

  const shuffle = (tPlane) => {
    let tempPlane = tPlane;
    let blank = blankIndex;
    for (let i = 0; i < 100; i++) {
      let moves = getValidMoves(blank);
      let moveIndex = Math.floor(Math.random() * moves.length);
      tempPlane = moveTile(...moves[moveIndex], tempPlane, blank);
      blank = moves[moveIndex];
    }
    setPlane(tempPlane);
    setBlankIndex(blank);
  };

  const checkWin = (tempPlane) => {
    let match = true;
    solution.map((e, ir) => {
      e.map((e, ic) => {
        if (tempPlane[ir][ic].number !== e) {
          match = false;
        }
      });
    });
    if (match === true) {
      setSolved(true);
    }
  };

  const handleStartGame = () => {
    if (isSubmitting) return;
    setPlayed(played + 1);
    let puzzleNumber = Math.floor(Math.random() * 5);
    setPlane(allTiles[puzzleNumber]);
    setImage(puzzles[`puzzle${puzzleNumber + 1}`][0]);
    setSolved(false);
    setTime(0);
    shuffle(allTiles[puzzleNumber]);
    setGameStarted(true);
  };

  const handleMoveTile = (ir, ic) => {
    let tempPlane = moveTile(ir, ic, plane, blankIndex);
    if (isValidMove(ir, ic, blankIndex)) {
      setBlankIndex([ir, ic]);
    }
    setPlane(tempPlane);
    checkWin(tempPlane);
  };
  const BorderedCard = ({ text, handleClick }) => {
    return (
      <div
        style={{
          boxShadow: "0 0 20px rgba(255,255,255,0.4) inset",
        }}
        onClick={handleClick}
        className=" sm:px-4 px-8 py-1 bg-black cursor-pointer hover:opacity-80 rounded-full border-2 border-primary"
      >
        <h1 className="font-redHat uppercase sm:text-base text-xs">{text}</h1>
      </div>
    );
  };
  const TimerCard = ({ time }) => {
    return (
      <div
        style={{ boxShadow: "0 0 10px rgba(255,255,255,0.7) inset" }}
        className="flex w-fit rounded-full 2xl:px-24 xl:px-16 sm:px-12 px-8 2xl:py-3 xl:py-[10px] py-2 border-2 border-primary  bg-black font-bold font-redHat uppercase items-center xl:gap-x-6 gap-x-4"
      >
        <span className="text-base">time</span>
        <h1 className=" text-[#ff3503] xl:text-[36px] lg:text-3xl text-2xl">
          {Math.floor(time / 60)
            .toString()
            .padStart(2, "0")}
          :{(time % 60).toString().padStart(2, "0")}
        </h1>
      </div>
    );
  };
  return (
    <div className="flex xl:pb-0 pb-4 min-h-screen w-full sm:px-8 px-4 md:pt-28 sm:pt-24 pt-20 ">
      <div className="md:container w-full  flex flex-col  mx-auto">
        <div className="flex md:flex-row flex-col lg:px-2 md:gap-y-0 gap-y-4 w-full md:justify-between  justify-center xl:items-center md:items-start items-center">
          <h1 className="xl:text-6xl lg:text-5xl md:text-4xl text-3xl font-bold font-akira">
            Puzzle Slider
          </h1>
          <RewardCard
            className="md:hidden flex"
            labelLeft={"Current Puzzle reward"}
            countLeft={currentEarnings}
            labelRight={"Total Puzzle reward"}
            countRight={totalRewards}
          />
          <div className="flex xl:flex-row flex-col   xl:gap-y-0 gap-y-3 xl:gap-x-4 xl:items-center md:items-end items-center">
            <TimerCard time={time} />
            <LearnButton
              title={"Learn to Play"}
              redirect={
                "https://medium.com/@blazpay/slide-solve-and-win-challenge-your-mind-and-earn-rewards-with-blazpays-puzzle-slider-c5041255e49c"
              }
              className={"w-fit"}
            />
          </div>
        </div>
        <div className="w-full  xl:mt-4 mt-8 justify-between items-center">
          <div className="flex w-full lg:justify-evenly justify-center lg:gap-x-0 sm:gap-x-6">
            <div
              className={`${
                showImage && "sm:grid hidden"
              } grid grid-cols-3 place-content-stretch`}
            >
              {plane.map((e, ir) => {
                return (
                  <>
                    {e.map((e, ic) => {
                      return (
                        <>
                          <Tile
                            data={e}
                            move={() => handleMoveTile(ir, ic)}
                            gameStarted={gameStarted}
                          />
                        </>
                      );
                    })}
                  </>
                );
              })}
            </div>
            <div>
              <img
                src={image}
                className={`${
                  !showImage && "sm:block hidden"
                }  ease-in-out duration-200 ${showImage ? "" : "opacity-0"}`}
              />
            </div>
          </div>
          <div className="flex  xl:mt-4 mt-8 sm:justify-center justify-between relative md:-left-0 sm:-left-4 lg:gap-x-12 sm:gap-x-8 lg:px-8   items-start w-full">
            <BorderedCard text={"Refresh Image"} handleClick={() => {}} />
            <RewardCard
              className="md:flex hidden"
              labelLeft={"Current Puzzle reward"}
              countLeft={currentEarnings}
              labelRight={"Total Puzzle reward"}
              countRight={totalRewards}
            />
            <BorderedCard
              text={showImage ? "Hide Image" : "Show Image"}
              handleClick={() => setShowImage(!showImage)}
            />
          </div>
          <div className="flex  sm:justify-center justify-between gap-x-8 items-center mt-4">
            <FilledButton
              text="Start"
              loadintText={"Playing..."}
              isLoading={gameStarted}
              className={"px-12"}
              value={gameStarted}
              disabled={gameStarted || isSubmitting}
              handleClick={handleStartGame}
            />
            <FilledButton
              text={isSubmitting ? "Submitting..." : "SUBMIT"}
              loadintText={"Submitting..."}
              isLoading={isSubmitting}
              disabled={gameStarted || isSubmitting}
              value={gameStarted}
              handleClick={handleBulkSlidingSubmit}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PuzzleSlider;
