import React from "react";
import taskCardLogo from "../../assets/task_card.svg";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
const CustomSlide = ({ className }) => {
  return (
    <div
      className={`${className} w-full relative sm:h-fit h-[210px] bg-[#141419] border border-[#666666] md:rounded-[30px] sm:rounded-3xl rounded-2xl flex justify-between items-center xl:px-16 md:px-10 sm:px-7 px-5 md:py-10 sm:py-8 py-5`}
    >
      <div className="flex sm:h-fit  h-full  flex-col items-start">
        <h1 className="xl:text-5xl lg:text-4xl md:text-3xl sm:text-2xl text-xl font-akira font-bold text-white">
          Daily Task
        </h1>
        <p className="xl:text-2xl lg:text-xl md:text-base sm:text-xs text-[10px] xl:mt-2 font-redHat">
          Do daily Tasks Earn Blazpoints
        </p>
        <h2 className="xl:text-[36px] xl:text-3xl sm:w-fit w-[29vw] lg:text-2xl md:text-xl sm:text-base text-[14px]  font-redHat mt-8">
          Complete the Task & Earn Blazpoints
        </h2>
      </div>
      <div className="md:shadow-[0_0_30px_rgba(255,155,45,0.9)] sm:relative absolute sm:bottom-0 bottom-7 sm:right-0  right-4 xl:rounded-[40px] lg:rounded-[30px] md:rounded-3xl sm:rounded-2xl rounded-xl xl:max-w-[500px] lg:max-w-[400px] md:max-w-[300px] sm:max-w-[240px] sm:w-fit w-[200px] 2xl:max-h-[300px]  sm:max-h-[280px]">
        <img
          src={taskCardLogo}
          className="xl:rounded-[40px] h-full w-full  lg:rounded-[30px] md:rounded-3xl sm:rounded-2xl rounded-xl"
        />
      </div>
    </div>
  );
};
const MainCard = ({ className }) => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    arrows: false,
  };
  return (
    <div className="w-full">
      {/* <Slider {...settings}>
        <CustomSlide />
        <CustomSlide />
        <CustomSlide />
      </Slider> */}
      <CustomSlide />
    </div>
  );
};

export default MainCard;
