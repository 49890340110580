import React, { useState, useEffect, useRef } from "react";
import { useWallet } from "../../hooks/useWallet";
import { useAuth } from "../../hooks/useAuth";
import { ethers } from "ethers";
import { getSignature } from "../../api/games";
import { toastPromise } from "../../services/util";
import { toast } from "react-toastify";
import axios from "axios";
import { apiCall } from "../../services/ApiCall";
import configs from "../../config/configs.json";
import LearnButton from "../../components/common/LearnButton";
import DropdownButton from "../../components/games/DropdownButton";
import ButtonCard from "../../components/games/ButtonCard";
import FilledButton from "../../components/games/FilledButton";
import RewardCard from "../../components/games/RewardCard";

const wordList2 = {
  nature: [
    "Forest",
    "River",
    "Ocean",
    "Desert",
    "Meadow",
    "Cliff",
    "Hill",
    "Breeze",
    "Jungle",
    "Lake",
    "Stream",
    "Earth",
    "Grove",
    "Sky",
    "Valley",
    "Rain",
    "Flora",
    "Fauna",
    "Stone",
    "Coral",
    "Arbor",
    "Moss",
    "Plain",
    "Grass",
    "Cloud",
    "Spray",
    "Cedar",
    "Ground",
    "Oil",
    "Sand",
  ],
  space: [
    "Galaxy",
    "Nebula",
    "Star",
    "Planet",
    "Orbit",
    "Comet",
    "Rocket",
    "Quasar",
    "Pulsar",
    "Light",
    "Vacuum",
    "Meteor",
    "Solar",
    "Lunar",
    "Cosmos",
    "Astro",
    "Saturn",
    "Venus",
    "Earth",
    "Pluto",
    "Space",
    "Mars",
    "Black",
    "Probe",
    "Radar",
    "Tides",
    "Phase",
    "Light",
    "Luce",
    "Milky",
  ],

  food: [
    "Pizza",
    "Burger",
    "Pasta",
    "Bread",
    "Cheese",
    "Fries",
    "Steak",
    "Apple",
    "Banana",
    "Salad",
    "Sushi",
    "Noodle",
    "Shrimp",
    "Bacon",
    "Cocoa",
    "Waffle",
    "Muffin",
    "Cookie",
    "Tacos",
    "Fudge",
    "Cereal",
    "Grains",
    "Beans",
    "Lemon",
    "Orange",
    "Peach",
    "Candy",
    "Toast",
    "Liver",
    "Cheeku",
  ],
  solid: [
    "cat",
    "dog",
    "mat",
    "sat",
    "rat",
    "kitty",
    "lol",
    "sing",
    "coke",
    "coin",
    "hard",
    "gum",
    "water",
    "food",
    "road",
    "turbo",
    "song",
    "swim",
    "hand",
    "race",
    "car",
    "head",
    "mice",
    "box",
    "cube",
    "sit",
    "dust",
    "rice",
    "ball",
    "cut",
  ],
};

const categories = ["solid", "nature", "space", "food"];
// const colors=['green', 'blue', 'yellow', 'red', 'orange', 'emerald', 'teal', 'cyan', 'violet']
const WordSearch = () => {
  let matched = 0;
  const wallet = useWallet();
  const auth = useAuth();
  const config = wallet.getChainConfig();

  const [highlightedCells, setHighlightedCells] = useState([]);
  const [startCell, setStartCell] = useState(null);

  // Direction - 0-down, 1-right, 2-diag, 3-diag up
  const [plane, setPlane] = useState([]);
  const [gameStarted, setGameStarted] = useState(false);
  const [selected, setSelected] = useState([-1, -1]);
  const [solution, setSolution] = useState([]);
  const [time, setTime] = useState(0);
  const [found, setFound] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [searchesLeft, setSearchesLeft] = useState(0);
  const [totalRewards, setTotalRewards] = useState(0);
  const [currentEarnings, setCurrentEarnings] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [score, setScore] = useState(0);
  const [played, setPlayed] = useState(0);
  const [foundWords, setFoundWords] = useState([]);
  const [count, setCount] = useState(0);
  const [originalrollsLeft, setOriginalrollsLeft] = useState(10);
  const [category, setCategory] = useState("solid");
  const wrapperRef = useRef(null);
  const getActivityCount = async () => {
    try {
      const { isSuccess, data } = await apiCall(
        `${configs.POST_LOGIN_API_URL}lightLink/getActivityCount`,
        {},
        {
          gameType: "WORDSEARCH",
          date: new Date(),
        },
        "GET"
      );
      if (isSuccess) {
        const count = parseInt(data.data?.processCount.toString(), 10);
        setOriginalrollsLeft(10 - count);
        setCount(count);
        setSearchesLeft(10 - count);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleMouseEnter = (r, c) => {
    if (startCell) {
      const newHighlightedCells = [];
      if (startCell[0] === r) {
        // Horizontal
        for (
          let i = Math.min(startCell[1], c);
          i <= Math.max(startCell[1], c);
          i++
        ) {
          newHighlightedCells.push([r, i]);
        }
      } else if (startCell[1] === c) {
        // Vertical
        for (
          let i = Math.min(startCell[0], r);
          i <= Math.max(startCell[0], r);
          i++
        ) {
          newHighlightedCells.push([i, c]);
        }
      } else if (Math.abs(startCell[0] - r) === Math.abs(startCell[1] - c)) {
        // Diagonal
        const stepX = startCell[1] < c ? 1 : -1;
        const stepY = startCell[0] < r ? 1 : -1;
        let x = startCell[1];
        let y = startCell[0];
        while ((stepX > 0 ? x <= c : x >= c) && (stepY > 0 ? y <= r : y >= r)) {
          newHighlightedCells.push([y, x]);
          x += stepX;
          y += stepY;
        }
      }
      setHighlightedCells(newHighlightedCells);
    }
  };
  const handleCellClick = (r, c) => {
    const wordsArray = solution.map((item) => item.word);
    const allElementsMatch = wordsArray.every((element) =>
      foundWords.includes(element)
    );
    if (allElementsMatch) return;
    if (!startCell) {
      setStartCell([r, c]);
      setHighlightedCells([[r, c]]);
    } else {
      const word = highlightedCells
        .map(([r, c]) => plane[r][c].alphabet)
        .join("");
      // word found
      const isCorrect = solution.some(
        (sol) =>
          sol.word === word || sol.word === word.split("").reverse().join("")
      );
      if (isCorrect) {
        // Update the plane to mark the word as found
        const newPlane = [...plane];
        highlightedCells.forEach(([r, c]) => {
          newPlane[r][c].matched = true;
        });
        setPlane(newPlane);
        setFound(found + 1);
        setFoundWords([...foundWords, word]);
      }
      // Reset the click regardless of whether the word is correct or not
      setStartCell(null);
      setHighlightedCells([]);
    }
  };
  const mintReward = async (amount) => {
    try {
      setIsSubmitting(true);
      const { isSuccess, data } = await apiCall(
        `${configs.POST_LOGIN_API_URL}mint/erc20`,
        {},
        {
          amount: String(amount),
        },
        "POST"
      );
      setIsSubmitting(false);

      if (isSuccess) {
        handleStart();
        getActivityCount();
        // updateRewardCount(amount);
        // updateActivityCount();
        wallet.updatePoints({
          amount: amount,
          chainId: wallet.chainId,
          walletAddress: wallet.address,
          moduleName: "wordsearch",
          txHash: "lightlink",
        });
        setTotalRewards(+totalRewards + amount);
        setCurrentEarnings(0);
        setPlayed(0);
        resetCrossword();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const updateRewardCount = async (reward) => {
    const res = await axios.get("/api/nft/all-balance");
    let multiplier = 1;
    if (res && res.multiplier > 1) {
      multiplier = res.multiplier;
    }
    const amount = Number(currentEarnings) * multiplier;
    try {
      const { isSuccess, data } = await apiCall(
        `${configs.POST_LOGIN_API_URL}lightLinkReward/updateRewardCount`,
        {},
        {
          reward: amount,
          gameType: "WORDSEARCH",
          date: new Date(),
          count: originalrollsLeft - searchesLeft,
        },
        "POST"
      );
      setIsSubmitting(false);

      if (isSuccess) {
        await mintReward(amount);
        getRewardCount();
        auth.updateTokens();
      } else {
        getActivityCount();
        setCurrentEarnings(0);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getRewardCount = async () => {
    try {
      const { isSuccess, data } = await apiCall(
        `${configs.POST_LOGIN_API_URL}lightLinkReward/getRewardCount`,
        {},
        { gameType: "WORDSEARCH" },
        "GET"
      );
      if (isSuccess) {
        setTotalRewards(data.data?.rewardCount);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const updateActivityCount = async () => {
    try {
      const { isSuccess, data } = await apiCall(
        `${configs.POST_LOGIN_API_URL}lightLink/updateActivityCount`,
        {},
        {
          gameType: "WORDSEARCH",
          date: new Date(),
          count: count + 1,
        },
        "POST"
      );
      if (isSuccess) {
      }
    } catch (error) {
      console.error(error);
    }
  };

  const init = async () => {
    setIsLoading(true);
    setCurrentEarnings(0);
    if (wallet.chainId === 1890 || wallet.chainId === 1891) {
      getActivityCount();
    } else {
      const [, left] = await wallet.safeCallContract({
        name: "Game",
        method: "getSearchesLeft",
        args: [wallet.address],
      });

      if (left) {
        const count = parseInt(left.toString(), 10);
        setSearchesLeft(count);
      }

      const [, rewards] = await wallet.safeCallContract({
        name: "Game",
        method: "getSearchRewards",
        args: [wallet.address],
      });

      if (rewards) {
        setTotalRewards(
          Number(ethers.utils.formatUnits(rewards.toString())).toFixed(0)
        );
      }

      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!wallet.address) return;
    init();
  }, [wallet.address, wallet.chainId]);
  useEffect(() => {
    if (!gameStarted) return;
    const intervalId = setInterval(() => {
      setTime((prevTime) => prevTime + 1);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [gameStarted]);

  useEffect(() => {
    const wordsArray = solution.map((item) => item.word);
    const allElementsMatch = wordsArray.every((element) =>
      foundWords.includes(element)
    );
    if (played && allElementsMatch) {
      setGameStarted(false);
      setSearchesLeft(searchesLeft - 1);
      setScore(calculateReward());
      setCurrentEarnings(currentEarnings + calculateReward());
    }
  }, [found]);
  useEffect(() => {
    if (wallet.chainId === 1890 || wallet.chainId === 1891) {
      getActivityCount();
      getRewardCount();
    }
  }, []);

  const calculateReward = () => {
    const reward = 150 - time;
    if (reward > 10) {
      return reward;
    } else return 10;
  };

  const resetCrossword = () => {
    setPlane([]);
    setSolution([]);
    setFound(0);
    setFoundWords([]);
    setTime(0);
    setScore(0);
    setGameStarted(false);
  };

  const handleBulkSearchSubmit = async () => {
    if (gameStarted) return;
    if (!played || currentEarnings === 0) {
      return toast.info("You must play at lease once");
    }

    setIsSubmitting(true);
    if (wallet.chainId === 1890 || wallet.chainId === 1891) {
      updateRewardCount();
    } else {
      try {
        const res = await axios.get("/api/nft/all-balance");
        let multiplier = 1;
        if (res && res.multiplier > 1) {
          multiplier = res.multiplier;
        }
        const amount = Number(currentEarnings) * multiplier;
        const message = "Word Search";
        const nonce = 123;
        const signature = await getSignature({
          to: wallet.address,
          amount: ethers.utils.parseEther(amount.toString()),
          message: message,
          nonce: nonce,
          chainId: wallet.chainId,
        });

        const [err, tx] = await wallet.safeCallContract({
          name: "Game",
          method: "bulkWordSearch",
          args: [
            played,
            ethers.utils.parseEther(amount.toString()),
            1,
            message,
            nonce,
            signature,
            {
              value: ethers.utils.parseEther(
                String((config.features.spinValue * played).toFixed(8))
              ),
            },
          ],
        });

        if (tx) {
          await toastPromise(tx.wait(), {
            pending: "Processing...",
            success: () => {
              wallet.updatePoints({
                amount: amount,
                chainId: wallet.chainId,
                walletAddress: wallet.address,
                moduleName: "wordsearch",
                txHash: tx.hash,
              });
              setTotalRewards(+totalRewards + amount);
              setCurrentEarnings(0);
              setPlayed(0);
              auth.updateTokens();
              resetCrossword();
              // setSearchesLeft(searchesLeft - 1);
              return "Search submitted successfully";
            },
            error: () => {
              return "Search Failed";
            },
          });
        }
      } catch (error) {
        console.log(error);
      }
      setIsSubmitting(false);
      setStartCell(null);
      setHighlightedCells([]);
    }
    setScore(0);
    setTime(0);
    setFound(0);
    setCurrentEarnings(0);
    setFoundWords([]);
    setHighlightedCells([]);
    setPlane([]);
  };

  const checkValid = (plane, word, r, c, direction) => {
    if (direction === 0 && word.length + r <= 5) {
      for (let i = 0; i < word.length; i++) {
        if (
          !(
            plane[i + r][c].alphabet === "0" ||
            plane[i + r][c].alphabet === word[i]
          )
        ) {
          return false;
        }
      }
    } else if (direction === 1 && word.length + c <= 5) {
      for (let i = 0; i < word.length; i++) {
        if (
          !(
            plane[r][c + i].alphabet === "0" ||
            plane[r][c + i].alphabet === word[i]
          )
        ) {
          return false;
        }
      }
    } else if (
      direction === 2 &&
      word.length + c <= 5 &&
      word.length + r <= 5
    ) {
      for (let i = 0; i < word.length; i++) {
        if (
          !(
            plane[r + i][c + i].alphabet === "0" ||
            plane[r + i][c + i].alphabet === word[i]
          )
        ) {
          return false;
        }
      }
    } else if (
      direction === 3 &&
      word.length + c <= 5 &&
      r - word.length >= 0
    ) {
      for (let i = 0; i < word.length; i++) {
        if (
          !(
            plane[r - i][c + i].alphabet === "0" ||
            plane[r - i][c + i].alphabet === word[i]
          )
        ) {
          return false;
        }
      }
    } else {
      return false;
    }
    return true;
  };
  const place = (plane, word, r, c, direction, tempSolution) => {
    const wordIndex = wordList2[category].indexOf(word) % 8;
    let i = 0;
    if (direction === 0) {
      for (i = 0; i < word.length; i++) {
        plane[r + i][c] = {
          alphabet: word[i],
          matched: false,
          highlight: false,
          word: wordIndex,
        };
      }
      tempSolution.push({
        word: word,
        startIndex: [r, c],
        endIndex: [r + i - 1, c],
      });
    } else if (direction === 1) {
      for (i = 0; i < word.length; i++) {
        plane[r][c + i] = {
          alphabet: word[i],
          matched: false,
          highlight: false,
          word: wordIndex,
        };
      }
      tempSolution.push({
        word: word,
        startIndex: [r, c],
        endIndex: [r, c + i - 1],
      });
    } else if (direction === 2) {
      for (i = 0; i < word.length; i++) {
        plane[r + i][c + i] = {
          alphabet: word[i],
          matched: false,
          highlight: false,
          word: wordIndex,
        };
      }
      tempSolution.push({
        word: word,
        startIndex: [r, c],
        endIndex: [r + i - 1, c + i - 1],
      });
    } else if (direction === 3) {
      for (i = 0; i < word.length; i++) {
        plane[r - i][c + i] = {
          alphabet: word[i],
          matched: false,
          highlight: false,
          word: wordIndex,
        };
      }
      tempSolution.push({
        word: word,
        startIndex: [r, c],
        endIndex: [r - i + 1, c + i - 1],
      });
    }
    return { plane, tempSolution };
  };
  const fillPlane = (plane) => {
    for (let i = 0; i < plane.length; i++) {
      for (let j = 0; j < plane.length; j++) {
        if (plane[i][j].alphabet === "0") {
          const alphabet = "abcdefghijklmnopqrstuvwxyz";
          plane[i][j] = {
            alphabet: alphabet[Math.floor(Math.random() * alphabet.length)],
            matched: false,
            highlight: false,
            word: 8,
          };
        }
      }
    }
    return plane;
  };
  const handleStart = () => {
    if (isSubmitting) return;
    setPlayed(played + 1);
    // setSearchesLeft((x) => x - 1);
    setScore(0);
    setTime(0);
    setFound(0);
    setFoundWords([]);
    const startIndex = Math.floor(Math.random() * wordList2[category].length);
    let words = [];
    for (let i = 0; i < 8; i++) {
      words.push(
        wordList2[category][(startIndex + i) % wordList2[category].length]
      );
    }
    let tempPlane = [];
    let temp = [{}];
    for (let i = 0; i < 6; i++) {
      temp = [{}];
      for (let j = 0; j < 6; j++) {
        temp[j] = {
          alphabet: "0",
          matched: false,
          highlight: false,
          word: 8,
        };
      }
      tempPlane[i] = temp;
    }
    let tempSolution = [];
    words.map((e) => {
      let found = 0;
      let count = 100;
      while (found === 0 && count > 0) {
        count--;
        let randomIndex = [
          Math.floor(Math.random() * 6),
          Math.floor(Math.random() * 6),
        ];
        let direction = Math.floor(Math.random() * 4);
        if (
          checkValid(tempPlane, e, randomIndex[0], randomIndex[1], direction)
        ) {
          found = 1;
          let placed = place(
            tempPlane,
            e,
            randomIndex[0],
            randomIndex[1],
            direction,
            tempSolution
          );
          tempPlane = placed.plane;
          tempSolution = placed.tempSolution;
        }
      }
    });

    tempPlane = fillPlane(tempPlane);

    setPlane(tempPlane);
    setSolution(tempSolution);
    setGameStarted(true);
  };
  const selection = (selected) => {
    console.log(selected);
    if (selected[0] !== -1 && selected[1] !== -1) {
      let matchFound = false;
      solution.map((e, i) => {
        if (
          e.startIndex[0] === selected[0][0] &&
          e.startIndex[1] === selected[0][1] &&
          e.endIndex[0] === selected[1][0] &&
          e.endIndex[1] === selected[1][1]
        ) {
          matchFound = true;
          if (!foundWords.includes(e.word)) {
            setFound(found + 1);
            setFoundWords([...foundWords, e.word]);
          }
        }
      });
      if (!matchFound) {
        setSelected([-1, -1]);
        return;
      }
      let tempPlane = plane;
      if (selected[0][0] === selected[1][0]) {
        // Direction right
        for (let i = 0; i <= selected[1][1] - selected[0][1]; i++) {
          tempPlane[selected[0][0]][selected[0][1] + i].matched = true;
        }
      }
      if (selected[0][1] === selected[1][1]) {
        // Direction down
        for (let i = 0; i <= selected[1][0] - selected[0][0]; i++) {
          tempPlane[selected[0][0] + i][selected[0][1]].matched = true;
        }
      }
      if (selected[0][0] - selected[1][0] === selected[0][1] - selected[1][1]) {
        // Direction diag
        for (let i = 0; i <= selected[1][0] - selected[0][0]; i++) {
          tempPlane[selected[0][0] + i][selected[0][1] + i].matched = true;
        }
      }
      if (selected[0][0] - selected[1][0] === selected[1][1] - selected[0][1]) {
        // Direction diag up
        for (let i = 0; i <= selected[1][1] - selected[0][1]; i++) {
          tempPlane[selected[0][0] - i][selected[0][1] + i].matched = true;
        }
      }
      setPlane(tempPlane);
      setSelected([-1, -1]);
    }
  };
  const handleChangeCategory = (category) => {
    if (gameStarted) {
      toast.error("First complete current game and then change category");
      return;
    }
    setCategory(category);
    setScore(0);
    setTime(0);
    setGameStarted(false);
    setFoundWords([]);
    setPlane([]);
    setSolution([]);
  };
  return (
    <div className="w-full min-h-screen xl:pb-0 pb-4 xl:px-5 px-8 2xl:pt-40 md:pt-28 sm:pt-24 pt-20">
      <div className="lg:container w-full mx-auto">
        <div className="flex w-full  md:flex-row flex-col items-center justify-between items-center">
          <h1 className=" 2xl:px-16 2xl:text-7xl lg:text-6xl xl:text-left text-center md:w-fit sm:text-4xl text-3xl font-bold font-akira">
            Word Search
          </h1>
          <LearnButton
            title={"Learn to Play"}
            className={"md:mt-0 mt-3"}
            redirect={
              "https://medium.com/@blazpay/unleash-your-inner-wordsmith-with-word-search-a40feb1c20c4"
            }
          />
        </div>
        <div
          style={{ boxShadow: "0 0 10px rgba(255,255,255,0.7) inset" }}
          className="w-full flex items-center xl:gap-x-12 lg:gap-x-6 sm:gap-x-4 gap-x-2 rounded-full border-[3px] border-[#ff3503] px-4 py-3 bg-black  xl:mt-7 mt-5"
        >
          <DropdownButton
            customRef={wrapperRef}
            category={category}
            categories={categories}
            setCatory={handleChangeCategory}
          />
          <div className="flex flex-wrap w-full xl:gap-x-6 md:gap-x-3 sm:gap-x-2 gap-x-[10px] items-center">
            {solution.length ? (
              solution.map((e, index) => {
                return (
                  <h1
                    key={index}
                    className={` ${
                      foundWords.includes(e.word) &&
                      "line-through decoration-[#ff3503] text-[#ff3503]"
                    } uppercase  xl:text-2xl lg:text-xl md:text-base text-xs font-bold font-redHat`}
                  >
                    {e.word}
                  </h1>
                );
              })
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
      <div className="flex xl:flex-row flex-col xl:items-start items-center xl:mt-2 sm:mt-8  lg:container w-full mx-auto justify-evenly">
        <div className="xl:w-1/2 2xl:mt-10 mt-5  w-full flex  justify-center items-center">
          {plane.length ? (
            <div className="flex-col">
              {plane.map((row, ir) => (
                <div className="flex" key={ir}>
                  {row.map((cell, ic) => {
                    return (
                      <div
                        key={ic}
                        className={`
            ${cell.matched ? "bg-green-500 border-none" : "bg-black"}
                ${
                  highlightedCells.some(([r, c]) => r === ir && c === ic)
                    ? startCell
                      ? "bg-yellow-300 border-none"
                      : "bg-green-300 border-none"
                    : ""
                }
                text-2xl font-bold hover:scale-[.95] transition-all duration-250 ease-in-out cursor-pointer 
                sm:w-16 w-12 sm:h-16 h-12 m-1 rounded-xl border border-primary 
                flex justify-center items-center 
              `}
                        onClick={() => handleCellClick(ir, ic)}
                        onMouseEnter={() => handleMouseEnter(ir, ic)}
                      >
                        {cell.alphabet.toUpperCase()}
                      </div>
                    );
                  })}
                </div>
              ))}
            </div>
          ) : (
            ""
          )}
        </div>

        {/* buttons */}
        <div className="xl:w-1/2 w-full mt-10  flex flex-col items-center">
          <div
            style={{ boxShadow: "0 0 10px rgba(255,255,255,0.7) inset" }}
            className="flex rounded-full sm:px-32 px-10 py-3 border-2 border-primary  bg-black font-bold font-redHat uppercase items-center gap-x-6"
          >
            <span className="">time</span>
            <h1 className=" text-[#ff3503] sm:text-[36px] text-xl">
              {Math.floor(time / 60)
                .toString()
                .padStart(2, "0")}
              :{(time % 60).toString().padStart(2, "0")}
            </h1>
          </div>
          <div className="flex w-full sm:w-fit items-center sm:gap-x-20 sm:mt-8 mt-4 justify-between">
            <ButtonCard text={"Daily Left"} value={searchesLeft} />
            <ButtonCard text={"Score"} value={score} />
          </div>
          <div className="flex flex-col 2xl:gap-y-6 gap-y-4 items-center  sm:mt-6 mt-4">
            {searchesLeft > 0 ? (
              <FilledButton
                handleClick={handleStart}
                text={"Play"}
                isLoading={gameStarted}
                disabled={gameStarted || isSubmitting}
                loadintText={"Playing..."}
                module={"game"}
              />
            ) : (
              <div
                style={{
                  boxShadow: "0 0 20px rgba(255,255,255,0.4) inset",
                }}
                className=" px-7 relative w-fit flex items-center justify-center py-1 bg-black rounded-full border-2 border-primary"
              >
                <h1 className="font-redHat text-center uppercase text-base">
                  No More Search Left! &nbsp;Access tomorrow
                </h1>
              </div>
            )}

            <RewardCard
              labelLeft="Current Word Search Rewards"
              labelRight="Total Word Search Reward"
              countLeft={currentEarnings}
              countRight={totalRewards}
            />
            <FilledButton
              handleClick={handleBulkSearchSubmit}
              text={"Submit"}
              isLoading={gameStarted || isSubmitting}
              loadintText={isSubmitting ? "Submitting..." : "SUBMIT"}
              module={"game"}
              disabled={gameStarted || isSubmitting}
            />
            <span className="relative -top-3 text-[10px]">
              Submit Before Leaving the Page
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WordSearch;
