import { useEffect, useState } from "react";
import CardLayout from "../../components/layout/CardLayout";
import { toast } from "react-toastify";
import { useAuth } from "../../hooks/useAuth";
import axios from "axios";
import useTable from "../../hooks/useTable";
import { chains } from "../../services/chains";
import FullScreenLoader from "../../components/Loader/FullScreenLoader";
import noTxn from "../../assets/icon 3-01.png";
import { useNavigate } from "react-router-dom";
import TransactionCard from "../../components/profile/TransactionCard";
import PaginationCard from "../../components/common/PaginationCard";
import FullPageLoader from "../../components/Loader/FullPageLoader";
const CustomHeader = () => {
  return (
    <ul className="flex justify-between items-center font-redHat font-bold py-3 leading-[1.2] bg-[#000] rounded-full border border-[#666666] sm:text-base text-xs 2xl:px-[50px] md:px-10 sm:px-6 px-2">
      <li>Mode</li>
      <li>Chain</li>
      <li>Module Name</li>
      <li>Date</li>
      <li>Points</li>
    </ul>
  );
};

const MyTransactions = () => {
  const auth = useAuth();
  const navigate = useNavigate();
  const { page, rowsPerPage, onNextPage, onPrevPage } = useTable();
  const [isLoading, setIsloading] = useState(false);
  const [isClaimLoading, setIsClaimLoading] = useState(false);
  const [totalPages, setTotalPages] = useState(0);
  const itemsPerPage = 10;
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    const getTransactions = async () => {
      try {
        setIsloading(true);
        const params = {
          page,
          limit: rowsPerPage,
        };
        const res = await axios.get("/api/transaction", { params });
        setTotalPages(Math.floor(res?.totalCount / 10));
        const data = res?.data.map((transaction) => {
          const chain = chains.find(
            (chain) => Number(chain.id) === Number(transaction.chainId)
          );
          return {
            ...transaction,
            chainName: chain?.name?.split(" ")[0] || "Blazpay",
            chainImage:
              chain?.tokenImg || "https://i.ibb.co/2kwFgSM/blazLogo.png",
          };
        });
        setIsloading(false);
        setTableData(data);
      } catch (error) {
        setIsloading(false);
        console.log("error", error);
      }
    };
    getTransactions();
  }, [page, rowsPerPage]);

  const claimKatle = async () => {
    setIsClaimLoading(true);
    try {
      const address = auth.user.loginAddress;
      console.log(address);
      const res = await axios.post("/api/user/claim-katla-balance");
      setIsClaimLoading(false);
      if (res.status === 200) {
        return toast.success("Claim Successful");
      } else {
        return toast.error("Something went wrong");
      }
    } catch (error) {
      console.log(error);
      setIsClaimLoading(false);
      toast.error(error.response.data.message);
    }
  };

  if (isLoading) return <FullPageLoader />;
  const handleBack = () => navigate(-1);
  return (
    <CardLayout
      className="2xl:h-[740px] sm:h-[680px] h-[660px] lg:px-[60px] md:px-8 sm:px-6 px-4 pt-8 w-full rounded-[30px]"
      handleBack={handleBack}
      title={"Recent Transaction"}
    >
      <div className="w-full mt-4 2xl:min-h-[520px] min-h-[460px]">
        <CustomHeader />
        <div className="2xl:h-[540px] h-[500px] relative mt-2 overflow-y-auto flex flex-col gap-y-2 w-full">
          {tableData.length === 0 ? (
            <div className="absolute top-1/2 left-1/2  -translate-x-1/2 -translate-y-1/2 ">
              <img src={noTxn} alt="" className="relative left-10 mb-4" />
              <h1 className="font-akira text-3xl">No Transactions yet!</h1>
            </div>
          ) : (
            <></>
          )}
          {tableData?.map((item) => {
            return (
              <TransactionCard
                _date={item?.createdAt}
                mode={item?.transfer}
                module={item?.moduleName}
                pointsEarned={item?.tokens}
                chain={item?.chainName}
                logoURI={item?.chainImage}
              />
            );
          })}
        </div>
      </div>
      {tableData.length !== 0 && (
        <>
          <div className="w-full  flex justify-center items-center">
            <PaginationCard
              isActive={true}
              currentPage={page}
              totalPages={page + totalPages}
              handleNextClick={onNextPage}
              handlePrevClick={onPrevPage}
            />
          </div>
        </>
      )}
    </CardLayout>
  );
};

export default MyTransactions;
