import React, { useState } from "react";
import { IoIosSearch } from "react-icons/io";
import bnb from "../../assets/bnb.png";
import ChainCard from "./ChainCard";

const NAV_ITEMS = [
  { value: "all", label: "All time" },
  { value: "daily", label: "Daily" },
  { value: "weekly", label: "Weekly" },
  { value: "monthly", label: "Monthly" },
  { value: "referral", label: "Referral" },
];
const LeaderboardNavs = ({
  className,
  currentNav,
  setCurrentNav,
  handleClick,
  handleSearch,
  setInputValue,
  value,
  chain,
  handleClickDropDown,
  isDropdownActive,
  referralInput,
  setReferralInput,
}) => {
  return (
    <div
      className={`${className}  relative mx-auto w-full 2xl:max-w-[1000px] xl:max-w-[800px] max-w-[760px] flex items-center justify-between md:gap-x-4 md:gap-x-3`}
    >
      <div className="relative">
        <input
          type="text"
          placeholder="Name"
          value={currentNav === "referral" ? referralInput : value}
          onChange={(e) =>
            currentNav === "referral"
              ? setReferralInput(e.target.value)
              : setInputValue(e.target.value)
          }
          onKeyUp={() => handleSearch()}
          className="outline-none 2xl:w-40 w-32 text-[14px] border bg-black placeholder:text-white border-[#666666] px-[10px] py-2 rounded-xl"
        />
        <IoIosSearch className="text-[#666666] absolute text-xl top-1/2 -translate-y-1/2 right-2" />
      </div>
      <ul className="flex  xl:gap-x-5 sm:gap-x-4 gap-x-2 2xl:text-xl xl:text-base md:text-[14px] sm:text-[11px] text-[8px] lg:px-4 items-center">
        {NAV_ITEMS.map((nav) => (
          <li
            key={nav.value}
            onClick={() => {
              handleClick(nav.value);
              setCurrentNav(nav.value);
            }}
            className={`cursor-pointer pb-2 border-primary ${
              currentNav === nav.value ? "border-b-2" : ""
            }`}
          >
            {nav.label}
          </li>
        ))}
      </ul>
      <ChainCard
        chain={chain}
        handleClick={handleClickDropDown}
        isDropdownActive={isDropdownActive}
      />
    </div>
  );
};

export default LeaderboardNavs;
