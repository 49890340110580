import React from "react";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";

const PaginationButton = ({ text, handleClick, isActive }) => {
  return (
    <>
      {isActive ? (
        <span
          onClick={handleClick}
          className="text-xs cursor-pointer hover:text-[#666666]"
        >
          {text}
        </span>
      ) : null}
    </>
  );
};
const PaginationCard = ({
  className,
  isActive,
  handlePrevClick,
  handleNextClick,
  currentPage,
  totalPages,
}) => {
  return (
    <>
      {isActive ? (
        <div className={`flex gap-x-2 items-center ${className}`}>
          <PaginationButton
            text={"Prev Page"}
            isActive={currentPage >= 2}
            handleClick={handlePrevClick}
          />
          <div className=" flex gap-x-1 items-center">
            <IoIosArrowBack className="text-xl group-hover:text-[#666666]" />
            <span className="text-[#ff3503]">{currentPage}</span>
            <IoIosArrowForward className="text-xl group-hover:text-[#666666]" />
          </div>
          <PaginationButton
            text="Next Page"
            isActive={currentPage >= 1 && currentPage < totalPages}
            handleClick={handleNextClick}
          />
        </div>
      ) : null}
    </>
  );
};

export default PaginationCard;
