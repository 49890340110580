import React from "react";

const TextField = ({ className, value, placeholder, disabled, setBio }) => {
  return (
    <textarea
      disabled={disabled}
      value={value}
      onChange={(e) => setBio(e.target.value)}
      placeholder={placeholder}
      className={`w-ful ${className} w-full resize-none 2xl:h-28 xl:h-[94px] h-[76px] text-white/70 bg-transparent outline-none focus:border-[#9a9a9a] focus:border-2 transition-all duration-250 sm:rounded-3xl rounded-2xl border border-[#666666] 2xl:text-base sm:text-[14px] text-xs 2xl:py-4 xl:py-3 py-2 xl:px-7 sm:px-5 px-4`}
    ></textarea>
  );
};

export default TextField;
