import React, { useEffect, useRef, useState } from "react";
import ProfileLayout from "../../components/layout/ProfileLaytout";
import { IoIosArrowBack } from "react-icons/io";
import { HiOutlinePencil } from "react-icons/hi";
import InputField from "../../components/profile/InputField";
import ProfileButton from "../../components/profile/ProfileButton";
import TextField from "../../components/profile/TextField";
import TokenField from "../../components/profile/TokenField";
import { useQuery } from "@tanstack/react-query";
import { getProfileData, updateProfileData } from "../../api/misc";
import avatarLogo1 from "../../assets/avatar/avatar1.png";
import avatarLogo2 from "../../assets/avatar/avatar2.png";
import avatarLogo3 from "../../assets/avatar/avatar3.png";
import avatarLogo4 from "../../assets/avatar/avatar4.png";
import avatarLogo5 from "../../assets/avatar/avatar5.png";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { FaDiscord, FaInstagram, FaTelegramPlane } from "react-icons/fa";
import { FaFacebookF, FaSquareXTwitter } from "react-icons/fa6";
const avatars = [
  avatarLogo1,
  avatarLogo2,
  avatarLogo3,
  avatarLogo4,
  avatarLogo5,
];
const ProfileSettings = () => {
  const [profileData, setProfileData] = useState(null);
  const [isEditProfileActive, setIsEditProfileActive] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");
  const [instaUsername, setInstaUsername] = useState("");
  const [telegramUsername, setTelegramUsername] = useState("");
  const [twitterUsername, setTwitterUsername] = useState("");
  const [discordUsername, setDiscordUsername] = useState("");
  const [isEditProfileImageActive, setIsEditProfileImageActive] =
    useState(false);
  const [email, setEmail] = useState("");
  const [bio, setBio] = useState("");
  const imgRef = useRef(null);
  const navigate = useNavigate();
  const profileQuery = useQuery({
    queryKey: ["user profile"],
    queryFn: () => getProfileData(),
  });

  useEffect(() => {
    if (!profileQuery?.isLoading) {
      setProfileData(profileQuery?.data);
    }
  }, [profileQuery?.isLoading]);
  const handleEditProfile = () => {
    if (!isEditProfileActive) {
      setIsEditProfileActive(true);
    }
  };
  useEffect(() => {
    if (profileData) {
      setEmail(profileData?.email || "");
      setBio(profileData?.bio || "");
      setInstaUsername(profileData?.socials?.instagram || "");
      setDiscordUsername(profileData?.socials?.discord || "");
      setTelegramUsername(profileData?.socials?.telegram || "");
      setTwitterUsername(profileData?.socials?.twitter || "");
    }
  }, [profileData]);
  const handleEditProfileImage = () => {
    imgRef.current?.click();
  };
  const handleSaveBtn = async () => {
    setIsEditProfileActive(false);
    const response = await fetch(selectedImage);
    const blob = await response.blob();
    const file = new File([blob], "image.png", { type: "image/png" });
    const formData = new FormData();
    formData.append("img_url", file);
    formData.append("email", email);
    formData.append("bio", bio);
    formData.append("instagram", instaUsername);
    formData.append("twitter", twitterUsername);
    formData.append("discord", discordUsername);
    formData.append("telegram", telegramUsername);
    try {
      const res = await updateProfileData(formData);
      profileQuery?.refetch();
      toast.success("Your profile updated successfully!");
    } catch (e) {
      toast.error("Getting error while profile updation");
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files?.[0];
    if (file) {
      const allowedTypes = [
        "image/png",
        "image/jpeg",
        "image/jpg",
        "image/svg+xml",
      ];
      if (!allowedTypes.includes(file.type)) {
        toast.error(
          "Please upload an image file of .png, .jpg, .jpeg, .svg format"
        );
        return;
      }
      const reader = new FileReader();
      reader.onload = (e) => {
        setSelectedImage(e.target?.result);
      };
      reader.readAsDataURL(file);
      setIsEditProfileImageActive(true);
    }
  };
  const handleBack = () => {
    navigate(-1);
  };
  return (
    <ProfileLayout
      title={"Profile Setting"}
      backButton={true}
      module="profile"
      className={"2xl:pt-8  lg:pt-6 pt-5 xl:pb-4 pb-2 lg:px-8 sm:px-6 px-3"}
      icon={
        <IoIosArrowBack
          onClick={handleBack}
          className="lg:text-4xl md:text-3xl sm:text-2xl text-xl hover:opacity-70 cursor-pointer font-bold"
        />
      }
    >
      <div className="2xl:mt-8 mt-6 mb-4 flex 2xl:px-16 xl:px-8  sm:px-4 lg:gap-x-6 md:gap-x-4 gap-x-3 w-full">
        <div className="flex  2xl:w-48 relative lg:w-40 md:w-32  w-28 flex-col items-center gap-y-2 items-start">
          <div className="lg:h-24  md:h-20 h-16 lg:w-24 md:w-20 w-16 relative rounded-full group relative">
            <img
              src={selectedImage || profileData?.img_url || avatars[0]}
              alt={"profile"}
              className="absolute border border-[#666666] group-hover:scale-[.96] transition-all duratin-[200ms] ease-in-out  h-full w-full rounded-full top-0 left-0"
            />
            <input
              type="file"
              className="hidden"
              ref={imgRef}
              onChange={handleFileChange}
            />
            <div
              onClick={handleEditProfileImage}
              className="md:h-7  h-5 md:w-7 w-5 cursor-pointer active:scale-[.95] 
            transition-scale ease duration-[250ms] flex items-center
             justify-center absolute lg:bottom-1 bottom-0 lg:right-1 right-0 
             rounded-full bg-[#ff5500] text-white lg:text-base md:text-xs text-[10px]"
            >
              <HiOutlinePencil />
            </div>
          </div>
          <span className="md:text-base text-xs font-light">User Name</span>
          <div
            onClick={handleEditProfile}
            className={`flex items-center py-1 lg:relative absolute lg:top-0 md:top-[120px]
               top-24 lg:w-fit md:w-[120px] w-[90px]  sm:gap-x-2 gap-x-1 cursor-pointer 
               bg-[#111111] active:scale-[.97] transition-all duration-[300ms] md:px-5
                sm:px-3 px-2 rounded-full border border-primary  ${
                  isEditProfileActive && "opacity-50"
                }`}
          >
            <span className="font-bold md:text-[10px] text-[8px]">
              Edit Profile
            </span>
            <HiOutlinePencil className="text-xs" />
          </div>
        </div>
        <div className="flex flex-col w-full">
          <div className="flex lg:gap-x-5 md:gap-x-3 md:flex-row flex-col items-start w-full">
            <div className="flex w-full items-center flex-col  xl:gap-y-3 gap-y-2">
              <h2 className=" w-full lg:text-base text-[13px] font-bold font-redHat relative lg:left-4 left-2">
                Profile Details
              </h2>

              <InputField
                disabled={true}
                placeholder={"User Name"}
                value={profileData?.username}
                module="profile"
              />
              <InputField
                disabled={!isEditProfileActive}
                placeholder={"E-mail Id"}
                value={email}
                type="email"
                onChange={(e) => setEmail(e.target.value)}
                module="profile"
              />
              <TextField
                value={bio}
                setBio={setBio}
                placeholder={"ADD Bio"}
                disabled={!isEditProfileActive}
              />
            </div>
            <div className="flex flex-col xl:gap-y-3 gap-y-2 md:mt-0 mt-3 items-start w-full h-full ">
              <h2 className=" w-full lg:text-base text-[13px] font-bold font-redHat relative lg:left-4 left-2">
                Social Accounts &nbsp;
                <span className="lg:text-[14px] text-xs font-medium text-white/80">
                  (usernames only)
                </span>
              </h2>
              <InputField
                disabled={!isEditProfileActive}
                placeholder={"Instagram"}
                type="text"
                logoState={true}
                logo={<FaInstagram className="text-[#E4405F]" />}
                onChange={(e) => setInstaUsername(e.target.value)}
                value={instaUsername}
                module="profile"
              />
              <InputField
                disabled={!isEditProfileActive}
                placeholder={"Twitter"}
                type="text"
                logoState={true}
                logo={<FaSquareXTwitter className="text-[#1DA1F2]" />}
                onChange={(e) => setTwitterUsername(e.target.value)}
                value={twitterUsername}
                module="profile"
              />
              <InputField
                disabled={!isEditProfileActive}
                placeholder={"Discord"}
                type="text"
                logoState={true}
                logo={<FaDiscord className="text-[#1877F2]" />}
                onChange={(e) => setDiscordUsername(e.target.value)}
                value={discordUsername}
                module="profile"
              />
              <InputField
                disabled={!isEditProfileActive}
                placeholder={"Telegram"}
                type="text"
                logoState={true}
                logo={<FaTelegramPlane className="text-[#0088CC]" />}
                onChange={(e) => setTelegramUsername(e.target.value)}
                value={telegramUsername}
                module="profile"
              />
            </div>
          </div>
          <div className="flex w-full mt-2 flex-col items-center gap-y-3 items-start">
            <h2 className="relative relative w-full text-left top-2 left-4 font-bold font-redHat">
              Your Address
            </h2>
            <TokenField
              value={profileData?.loginAddress}
              disabled={true}
              module={"address"}
            />
            <TokenField module={"solana"} />
            <ProfileButton
              module="profile"
              text={"Save"}
              disabled={!isEditProfileActive}
              className={`${
                !isEditProfileActive &&
                !isEditProfileImageActive &&
                "opacity-60"
              }`}
              handleSubmit={handleSaveBtn}
            />
          </div>
        </div>
      </div>
    </ProfileLayout>
  );
};

export default ProfileSettings;
