import axios from "axios";
import { select } from "d3";

export const getLeaderboard = (params = {}) => {
  return axios.get("/api/user/leaderboard", {
    params,
  });
};

export const getQuestions = async (chainId) => {
  const response = await axios.get(`/api/questions?chainId=${chainId}`);
  return response.filter((question) => question).slice(0, 10);
};

export const getStakingInfo = () => {
  return axios.get("/api/nft/stakingInfo");
};

export const stakeNow = (tokenId) => {
  return axios.post(`/api/nft/${tokenId}/stakeNow`);
};

export const completeTransaction = (data) => {
  return axios.post("/api/game/done", data);
};

export const getDailyTasks = async () => {
  return axios.get("/api/task/all-task");
};

export const getLeaderboardReferrals = async () => {
  return axios.get("/api/user/leaderboard?referral=true");
};
export const getProfileData = async () => {
  return axios.get("/api/user/profile");
};
export const updateProfileData = async (formData) => {
  return axios.patch("/api/user/profile", formData);
};
export const getCalculator = async () => {
  return axios.get("/api/misc/calculate");
};

export const clearCart = async (chainId) => {
  return axios.post(`/api/cart/empty/${chainId}`);
};

export const getRecentTransactions = async (params) => {
  return axios.get("/api/transaction", { params });
};
