import React, { useState, useEffect } from "react";
import { useWallet } from "../../hooks/useWallet";
import { useAuth } from "../../hooks/useAuth";
import { ethers } from "ethers";
import { getSignature } from "../../api/games";
import { toastPromise } from "../../services/util";
import { toast } from "react-toastify";
import axios from "axios";
import "../../assets/css/memory.css";
import { apiCall } from "../../services/ApiCall";
import configs from "../../config/configs.json";
import ButtonCard from "../../components/games/ButtonCard";
import FilledButton from "../../components/games/FilledButton";
import Tile from "../../components/games/Tile";
import RewardCard from "../../components/games/RewardCard";
import btcLogo from "../../assets/memory_btc.png";
import tronLogo from "../../assets/memory_tron.png";
import maticLogo from "../../assets/memory_matic.png";
import usdcLogo from "../../assets/memory_usdc.png";
import solLogo from "../../assets/memory_sol.png";
import ethLogo from "../../assets/memory_eth.png";
import LearnButton from "../../components/common/LearnButton";
const Memory = () => {
  const [tiles, setTiles] = useState([
    { image: tronLogo, matched: false },
    { image: tronLogo, matched: false },
    { image: btcLogo, matched: false },
    { image: btcLogo, matched: false },
    { image: ethLogo, matched: false },
    { image: ethLogo, matched: false },
    { image: maticLogo, matched: false },
    { image: maticLogo, matched: false },
    { image: solLogo, matched: false },
    { image: solLogo, matched: false },
    { image: usdcLogo, matched: false },
    { image: usdcLogo, matched: false },
  ]);
  const [choiceOne, setChoiceOne] = useState(-1);
  const [choiceTwo, setChoiceTwo] = useState(-1);
  const [solved, setSolved] = useState(0);
  const [gameStarted, setGameStarted] = useState(false);
  const [time, setTime] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [memoryGamesLeft, setMemoryGamesLeft] = useState(0);
  const [totalRewards, setTotalRewards] = useState(0);
  const [currentEarnings, setCurrentEarnings] = useState(0);
  const [played, setPlayed] = useState(0);
  const [originalrollsLeft, setOriginalrollsLeft] = useState(10);

  const wallet = useWallet();
  const auth = useAuth();
  const config = wallet.getChainConfig();
  const getActivityCount = async () => {
    try {
      const { isSuccess, data } = await apiCall(
        `${configs.POST_LOGIN_API_URL}lightLink/getActivityCount`,
        {},
        {
          gameType: "MEMORYMATCHING",
          date: new Date(),
        },
        "GET"
      );
      if (isSuccess) {
        const count = parseInt(data.data?.processCount.toString(), 10);
        setOriginalrollsLeft(10 - count);
        setMemoryGamesLeft(10 - count);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const mintReward = async (amount) => {
    try {
      setIsSubmitting(true);
      const { isSuccess, data } = await apiCall(
        `${configs.POST_LOGIN_API_URL}mint/erc20`,
        {},
        {
          amount: String(amount),
        },
        "POST"
      );
      setIsSubmitting(false);

      if (isSuccess) {
        handleStartGame();
        wallet.updatePoints({
          amount: amount,
          chainId: wallet.chainId,
          walletAddress: wallet.address,
          moduleName: "memorygame",
          txHash: "lightlink",
        });
        setTotalRewards(+totalRewards + amount);
        setCurrentEarnings(0);
        setPlayed(0);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const updateRewardCount = async () => {
    const res = await axios.get("/api/nft/all-balance");
    let multiplier = 1;
    if (res && res.multiplier > 1) {
      multiplier = res.multiplier;
    }
    const amount = Number(currentEarnings) * multiplier;
    try {
      const { isSuccess, data } = await apiCall(
        `${configs.POST_LOGIN_API_URL}lightLinkReward/updateRewardCount`,
        {},
        {
          reward: amount,
          gameType: "MEMORYMATCHING",
          date: new Date(),
          count: originalrollsLeft - memoryGamesLeft,
        },
        "POST"
      );
      setIsSubmitting(false);
      if (isSuccess) {
        await mintReward(amount);
        getRewardCount();
        auth.updateTokens();
      } else {
        getActivityCount();
        setCurrentEarnings(0);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getRewardCount = async () => {
    try {
      const { isSuccess, data } = await apiCall(
        `${configs.POST_LOGIN_API_URL}lightLinkReward/getRewardCount`,
        {},
        { gameType: "MEMORYMATCHING" },
        "GET"
      );
      if (isSuccess) {
        setTotalRewards(data.data?.rewardCount);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const updateActivityCount = async () => {
    try {
      const { isSuccess, data } = await apiCall(
        `${configs.POST_LOGIN_API_URL}lightLink/updateActivityCount`,
        {},
        {
          gameType: "MEMORYMATCHING",
          date: new Date(),
          count: 10 - memoryGamesLeft,
        },
        "POST"
      );
      if (isSuccess) {
      }
    } catch (error) {
      console.error(error);
    }
  };

  const init = async () => {
    setIsLoading(true);

    setCurrentEarnings(0);
    if (wallet.chainId === 1890 || wallet.chainId === 1891) {
      getActivityCount();
    } else {
      const [, left] = await wallet.safeCallContract({
        name: "Game",
        method: "getMemoryLeft",
        args: [wallet.address],
      });

      if (left) {
        const count = parseInt(left.toString(), 10);
        setMemoryGamesLeft(count);
      }

      const [, rewards] = await wallet.safeCallContract({
        name: "Game",
        method: "getMemoryRewards",
        args: [wallet.address],
      });

      if (rewards) {
        setTotalRewards(
          Number(ethers.utils.formatUnits(rewards.toString())).toFixed(0)
        );
      }

      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!wallet.address) return;

    init();
  }, [wallet.address, wallet.chainId]);

  useEffect(() => {
    if (wallet.chainId === 1890 || wallet.chainId === 1891) {
      getActivityCount();
      getRewardCount();
    }
  }, []);

  useEffect(() => {
    if (gameStarted) {
      setTimeout(() => {
        setTime(time + 1);
      }, 1000);
    }
  });

  useEffect(() => {
    if (choiceOne !== -1 && choiceTwo !== -1) {
      if (tiles[choiceOne].image === tiles[choiceTwo].image) {
        const tempTiles = tiles;
        tempTiles[choiceOne].matched = true;
        tempTiles[choiceTwo].matched = true;
        if (solved + 2 === 10 && played) {
          setCurrentEarnings(currentEarnings + calculateReward());
          setMemoryGamesLeft(memoryGamesLeft - 1);
          setGameStarted(false);
          // handleStartGame();
        }
        setTiles(tempTiles);
        setSolved(solved + 2);
      }
      setTimeout(() => {
        setChoiceOne(-1);
        setChoiceTwo(-1);
      }, 500);
    }
  }, [choiceOne, choiceTwo]);

  const calculateReward = () => {
    const reward = 60 - time;
    if (reward > 10) {
      return reward;
    } else return 10;
  };

  const handleBulkSearchSubmit = async () => {
    if (gameStarted) return;
    if (!played || currentEarnings === 0) {
      return toast.info("You must play at lease once");
    }

    setIsSubmitting(true);
    if (wallet.chainId === 1890 || wallet.chainId === 1891) {
      updateRewardCount();
    } else {
      try {
        const res = await axios.get("/api/nft/all-balance");
        let multiplier = 1;
        if (res && res.multiplier > 1) {
          multiplier = res.multiplier;
        }
        const amount = Number(currentEarnings) * multiplier;

        const message = "Memory Game";
        const nonce = 456;

        const signature = await getSignature({
          to: wallet.address,
          amount: ethers.utils.parseEther(amount.toString()),
          message: message,
          nonce: nonce,
          chainId: wallet.chainId,
        });

        const [err, tx] = await wallet.safeCallContract({
          name: "Game",
          method: "bulkMemoryGame",
          args: [
            played,
            ethers.utils.parseEther(amount.toString()),
            1,
            message,
            nonce,
            signature,
            {
              // gasLimit: 21000,
              value: ethers.utils.parseEther(
                String((config.features.spinValue * played).toFixed(8))
              ),
            },
          ],
        });

        if (tx) {
          await toastPromise(tx.wait(), {
            pending: "Processing...",
            success: () => {
              wallet.updatePoints({
                amount: amount,
                chainId: wallet.chainId,
                walletAddress: wallet.address,
                moduleName: "memorygame",
                txHash: tx.hash,
              });
              setTotalRewards(+totalRewards + amount);
              setCurrentEarnings(0);
              setPlayed(0);
              auth.updateTokens();
              return "Memory Game submitted successfully";
            },
            error: () => {
              return "Submit Failed";
            },
          });
        }
      } catch (error) {
        console.log(error);
      }

      setIsSubmitting(false);
    }
  };

  const handleFlip = (i) => {
    if (choiceOne === -1) {
      setChoiceOne(i);
    } else {
      if (choiceTwo === -1 && choiceOne !== i) {
        setChoiceTwo(i);
      }
    }
  };
  const handleStartGame = () => {
    if (isSubmitting) return;
    setTime(0);
    setPlayed(played + 1);
    setSolved(0);
    let tilesArr = tiles;
    for (let i = 0; i < tilesArr.length; i++) {
      let randomIndex = Math.floor(Math.random() * tilesArr.length);
      let temp = tilesArr[i];
      tilesArr[i] = tilesArr[randomIndex];
      tilesArr[randomIndex] = temp;
    }
    for (let i = 0; i < tilesArr.length; i++) {
      tilesArr[i].matched = false;
    }
    setTiles(tilesArr);
    if (!gameStarted) {
      setTime(0);
    }
    setGameStarted(true);
  };

  return (
    <div className="min-h-screen w-full md:pt-28 sm:pt-24 pt-20">
      <div className="md:container xl:pb-0 pb-4 w-full lg:px-16 sm:px-12 px-8 mx-auto">
        <div className="flex md:flex-row flex-col items-center justify-between w-full">
          <h1 className="xl:text-6xl lg:text-4xl  md:w-fit w-full  text-3xl md:text-left text-center font-bold font-akira">
            Memory Matching
          </h1>
          <LearnButton
            redirect={
              "https://medium.com/@blazpay/match-earn-repeat-conquer-the-memory-game-challenge-f4febec875a1"
            }
            title={"Learn to Play"}
            className={"md:mt-0 mt-3"}
          />
        </div>
        <div className="grid 2xl:mt-8 mt-6 md:gap-4 sm:gap-3 gap-2 2xl:px-16 xl:px-8 lg:px-10 md:px-4 xl:grid-cols-6 md:grid-cols-4 grid-cols-3 w-full">
          {tiles.map((e, i) => (
            <Tile
              key={i}
              image={e.image}
              id={i}
              gameStarted={gameStarted}
              handleFlip={() => handleFlip(i)}
              flipped={
                choiceOne === i || choiceTwo === i || e.matched || !gameStarted
              }
            />
          ))}
        </div>
        <div className="flex relative items-start lg:justify-between justify-center xl:px-16 w-full 2xl:mt-8 xl:mt-4 lg:mt-8 mt-20">
          <ButtonCard
            text={"Attemps"}
            value={memoryGamesLeft}
            className={"lg:relative absolute left-0 lg:top-0 -top-16"}
          />
          <div className="flex xl:gap-y-3  gap-y-5 flex-col items-center">
            {memoryGamesLeft > 0 ? (
              <FilledButton
                text="Play"
                isLoading={gameStarted}
                handleClick={handleStartGame}
                loadintText={"Playing..."}
                disabled={isSubmitting || gameStarted}
              />
            ) : (
              <div
                style={{
                  boxShadow: "0 0 20px rgba(255,255,255,0.4) inset",
                }}
                className=" px-7 relative w-fit flex items-center justify-center py-1 bg-black rounded-full border-2 border-primary"
              >
                <h1 className="font-redHat text-center uppercase text-base">
                  No More Match Left! &nbsp;Access tomorrow
                </h1>
              </div>
            )}

            <RewardCard
              labelLeft={"Current word search reward"}
              countLeft={currentEarnings}
              labelRight={"Total word search reward"}
              countRight={totalRewards}
            />
            <FilledButton
              text="Submit"
              loadintText={"Submitting..."}
              isLoading={isSubmitting}
              handleClick={handleBulkSearchSubmit}
              disabled={gameStarted || isSubmitting}
              module="game"
            />
          </div>

          <ButtonCard
            className={"lg:relative absolute right-0 lg:top-0 -top-16"}
            text={"Score"}
            value={`${solved / 2}5`}
          />
        </div>
      </div>
    </div>
  );
};

export default Memory;
