import { useEffect, useState } from "react";
import "./Loader.css";
const FullPageLoader = () => {
  const [currentMessage, setCurrentMessage] = useState("");
  const messages = [
    "Welcome to Blazpay",
    "AI Powered Defi..",
    "Future of Defi",
    "AI Powered Defi..",
  ];
  let messageIndex = 0;

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentMessage(messages[messageIndex]);
      messageIndex = (messageIndex + 1) % messages.length;
    }, 400);

    return () => {
      clearInterval(interval);
    };
  }, [messages]);

  return (
    <section className="w-full fixed bg-black z-[10000] top-0 left-0 min-h-screen ">
      <div className="container-loader-f">
        <div className="loader-f"></div>
        <p id="message-1" className="text-white loader">
          {currentMessage}
        </p>
      </div>
    </section>
  );
};

export default FullPageLoader;
