import { useEffect, useState } from "react";
import { Wheel } from "react-custom-roulette";
import { ethers } from "ethers";
import { toast } from "react-toastify";
import { useWallet } from "../../hooks/useWallet";
import { useAuth } from "../../hooks/useAuth";
import { toastPromise } from "../../services/util";
import FullScreenLoader from "../../components/Loader/FullScreenLoader";
import { parseEther } from "ethers/lib/utils";
import { getSignature } from "../../api/games";
import { apiCall } from "../../services/ApiCall";
import pointerWheel from "../../assets/wheel_pointer.svg";
import configs from "../../config/configs.json";
import axios from "axios";
import FilledButton from "../../components/games/FilledButton";
import ButtonCard from "../../components/games/ButtonCard";
import JackpotButtonCard from "../../components/games/JackpotButtonCard";
import wheelBg from "../../assets/wheel_bg.svg";
import wheelCenter from "../../assets/wheel_center.svg";
import LearnButton from "../../components/common/LearnButton";
import FullPageLoader from "../../components/Loader/FullPageLoader";
const segColors = [
  "#cf372c",
  "#e08025",
  "#7d1a17",
  "#92310e",
  "#480d14",
  "#e52835",
  "#400d1c",
  "#e52c33",
  "#f4972a",
  "#7d1a17",
  "#92310e",
  "#480d14",
];

const segments = [1, 5, 10, 15, 20, 25, 30, 35, 40, 50, 60, 100];

const data = segments.map((segment) => ({
  option: `${segment} BLZ`, // Add the label "BLZ" to each segment
  style: {
    textColor: "black", // Text color for better contrast
  },
}));

const Spin = () => {
  const [mustSpin, setMustSpin] = useState(false);
  const [spinButton, setSpinButton] = useState(false);
  const [prizeNumber, setPrizeNumber] = useState(0);
  const [balance, setBalance] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [currentEarnings, setCurrentEarnings] = useState(0);
  const [totalSpins, setTotalSpins] = useState(0);
  const [spinsLeft, setSpinsLeft] = useState(0);
  const [totalRewards, setTotalRewards] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [originalrollsLeft, setOriginalrollsLeft] = useState(10);
  const [pointerStyles, setPointerStyles] = useState({
    height: "100%",
    width: "120px",
    position: "absolute",
    right: "0",
    top: "-170px",
  });

  const wallet = useWallet();
  const auth = useAuth();
  const config = wallet.getChainConfig();

  const handleSpinClick = () => {
    if (isSubmitting) return;
    if (spinsLeft <= 0) {
      toast.error("No spins left");
      return;
    }

    const newPrizeNumber = Math.floor(Math.random() * segments.length);

    setPrizeNumber(newPrizeNumber);
    setSpinButton(true);
    setMustSpin(true);
  };

  const handleSpinFinished = () => {
    const prizeValue = segments[prizeNumber];
    setCurrentEarnings(currentEarnings + prizeValue);
    setTotalSpins(totalSpins + 1);
    setSpinsLeft(spinsLeft - 1);
    setSpinButton(false);
    setMustSpin(false);
  };

  const getActivityCount = async () => {
    try {
      const { isSuccess, data } = await apiCall(
        `${configs.POST_LOGIN_API_URL}lightLink/getActivityCount`,
        {},
        {
          gameType: "SPINTHEWHEEL",
          date: new Date(),
        },
        "GET"
      );
      if (isSuccess) {
        const count = parseInt(data.data?.processCount.toString(), 10);
        setOriginalrollsLeft(10 - count);
        setBalance(10 - count);
        setSpinsLeft(10 - count);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const mintReward = async (amount) => {
    setIsSubmitting(true);
    try {
      const { isSuccess, data } = await apiCall(
        `${configs.POST_LOGIN_API_URL}mint/erc20`,
        {},
        {
          amount: String(amount),
        },
        "POST"
      );
      setIsSubmitting(false);

      if (isSuccess) {
        getActivityCount();
        wallet.updatePoints({
          amount: amount,
          chainId: wallet.chainId,
          walletAddress: wallet.address,
          moduleName: "spin",
          txHash: "lightlink",
        });
        setTotalRewards(+totalRewards + amount);
        setCurrentEarnings(0);
        setTotalSpins(0);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const updateRewardCount = async () => {
    const res = await axios.get("/api/nft/all-balance");
    let multiplier = 1;
    if (res && res.multiplier > 1) {
      multiplier = res.multiplier;
    }
    const amount = Number(currentEarnings) * multiplier;

    try {
      const { isSuccess, data } = await apiCall(
        `${configs.POST_LOGIN_API_URL}lightLinkReward/updateRewardCount`,
        {},
        {
          reward: amount,
          gameType: "SPINTHEWHEEL",
          date: new Date(),
          count: originalrollsLeft - spinsLeft,
        },
        "POST"
      );
      setIsSubmitting(false);
      if (isSuccess) {
        await mintReward(amount);
        getRewardCount();
        auth.updateTokens();
      } else {
        getActivityCount();
        setCurrentEarnings(0);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getRewardCount = async () => {
    try {
      const { isSuccess, data } = await apiCall(
        `${configs.POST_LOGIN_API_URL}lightLinkReward/getRewardCount`,
        {},
        { gameType: "SPINTHEWHEEL" },
        "GET"
      );
      if (isSuccess) {
        setTotalRewards(data.data?.rewardCount);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const updateActivityCount = async () => {
    try {
      const { isSuccess, data } = await apiCall(
        `${configs.POST_LOGIN_API_URL}lightLink/updateActivityCount`,
        {},
        {
          gameType: "SPINTHEWHEEL",
          date: new Date(),
          count: 10 - spinsLeft,
        },
        "POST"
      );
      if (isSuccess) {
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleBulkSpinSubmit = async () => {
    if (spinButton) return;
    if (totalSpins === 0) {
      return toast.info("You must spin at lease once");
    }

    setIsSubmitting(true);
    if (wallet.chainId === 1890 || wallet.chainId === 1891) {
      updateRewardCount();
    } else {
      const transactionOptions = {
        value: parseEther(
          String((config.features.spinValue * totalSpins).toFixed(8))
        ),
        ...(config.features.gasLimit && { gasLimit: "900000" }),
      };

      const message = "Spin the wheel";
      const nonce = 141;

      const signature = await getSignature({
        to: wallet.address,
        amount: ethers.utils.parseEther(currentEarnings.toString()),
        message: message,
        nonce: nonce,
        chainId: wallet.chainId,
      });

      const [err, tx] = await wallet.safeCallContract({
        name: "Game",
        method: "bulkSpin",
        args: [
          totalSpins,
          ethers.utils.parseEther(currentEarnings.toString()),
          1,
          message,
          nonce,
          signature,
          transactionOptions,
        ],
      });

      if (tx) {
        await toastPromise(tx.wait(), {
          pending: "Processing...",
          success: () => {
            wallet.updatePoints({
              amount: currentEarnings,
              chainId: wallet.chainId,
              walletAddress: wallet.address,
              moduleName: "spin",
              txHash: tx.hash,
            });
            setTotalRewards(+totalRewards + currentEarnings);
            setCurrentEarnings(0);
            setTotalSpins(0);
            auth.updateTokens();
            return "Spins submitted successfully";
          },
          error: () => {
            return "Spin Failed";
          },
        });
      }

      setIsSubmitting(false);
    }
  };

  const init = async () => {
    setIsLoading(true);
    setCurrentEarnings(0);
    if (wallet.chainId === 1890 || wallet.chainId === 1891) {
      getActivityCount();
    } else {
      const [, left] = await wallet.safeCallContract({
        name: "Game",
        method: "getSpinsLeft",
        args: [wallet.address],
      });

      if (left) {
        const count = parseInt(left.toString(), 10);
        setBalance(count);
        setSpinsLeft(count);
      }

      const [, rewards] = await wallet.safeCallContract({
        name: "Game",
        method: "getSpinnerRewards",
        args: [wallet.address],
      });

      if (rewards) {
        setTotalRewards(
          Number(ethers.utils.formatUnits(rewards.toString())).toFixed(0)
        );
      }
    }

    setIsLoading(false);
  };

  useEffect(() => {
    if (!wallet.address) return;
    init();
  }, [wallet.address, wallet.chainId]);

  useEffect(() => {
    const updateStyles = () => {
      if (window.innerWidth < 768) {
        setPointerStyles({
          height: "80%",
          width: "80px",
          position: "absolute",
          right: "0",
          top: "-120px",
        });
      }
    };

    updateStyles();
    window.addEventListener("resize", updateStyles);
    return () => window.removeEventListener("resize", updateStyles);
  }, []);
  useEffect(() => {
    if (wallet.chainId === 1890 || wallet.chainId === 1891) {
      getActivityCount();
      getRewardCount();
    }
  }, []);

  if (isLoading) return <FullPageLoader />;
  return (
    <div className="flex 2xl:pt-40 md:pt-28 sm:pt-24 pt-20 xl:px-5 px-8 2xl:pb-0 pb-4  mx-auto min-h-screen">
      <div className="container  mx-auto flex flex-col items-start">
        <div className="flex w-full  md:flex-row flex-col items-center justify-between items-center">
          <h1 className=" 2xl:px-16 2xl:text-7xl lg:text-6xl xl:text-left text-center md:w-fit w-full sm:text-4xl text-3xl font-bold font-akira">
            Spin & Win
          </h1>
          <LearnButton
            title={"Learn to Play"}
            className={"xl:hidden block md:mt-0 sm:mt-2 mt-3"}
            redirect={
              "https://medium.com/@blazpay/spin-to-win-big-test-your-luck-and-earn-blz-rewards-daily-with-blazpays-spin-win-abc65990150e"
            }
          />
        </div>
        <div className="w-full xl:px-10 2xl:mt-10 mt-5 flex xl:flex-row flex-col  items-center mx-auto">
          <div className="xl:w-1/2 w-full flex flex-col items-center ">
            {balance ? (
              <div className="w-full flex flex-col items-center">
                <div className="flex p-5 sm:w-[600px] w-full  h-full relative flex-col items-center">
                  <Wheel
                    mustStartSpinning={mustSpin}
                    prizeNumber={prizeNumber}
                    data={data}
                    onStopSpinning={handleSpinFinished}
                    backgroundColors={segColors}
                    textColors={"[#fff]"}
                    outerBorderColor={"transparent"}
                    outerBorderWidth={10}
                    innerRadius={15}
                    innerBorderColor={"transparent"}
                    innerBorderWidth={5}
                    radiusLineWidth={0}
                    fontSize={20}
                    textDistance={65}
                    pointerProps={{
                      src: pointerWheel,
                      style: pointerStyles,
                    }}
                  />

                  <img
                    src={wheelBg}
                    className="absolute sm:scale-100 scale-[1.13] left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2 max-w-[510px]  w-[80vw]  -z-1"
                    alt=""
                  />
                  <img
                    src={wheelCenter}
                    className="absolute sm:scale-100 scale-[.90] top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
                  />
                </div>

                {spinsLeft > 0 ? (
                  <div className="2xl:mt-16 mt-12 flex gap-x-10 items-center">
                    <ButtonCard text={"Spin Left"} value={spinsLeft} />
                    <FilledButton
                      handleClick={handleSpinClick}
                      text={spinButton ? "Spinning..." : "Spin"}
                      isLoading={spinButton}
                      loadintText={"Spinning..."}
                    />
                  </div>
                ) : (
                  <div
                    style={{
                      boxShadow: "0 0 20px rgba(255,255,255,0.4) inset",
                    }}
                    className=" px-7 2xl:mt-16 mt-12 w-fit flex items-center justify-center py-1 bg-black rounded-full border-2 border-primary"
                  >
                    <h1 className="font-redHat uppercase text-base">
                      No More Spin Left! Access Tomorrow
                    </h1>
                  </div>
                )}
              </div>
            ) : (
              <></>
            )}
          </div>
          <div className="xl:w-1/2 w-full xl:mt-0 mt-8 flex flex-col 2xl:gap-y-7 gap-y-5  items-center justify-center">
            <LearnButton
              title={"Learn to Play"}
              className={"xl:block hidden"}
              redirect={
                "https://medium.com/@blazpay/spin-to-win-big-test-your-luck-and-earn-blz-rewards-daily-with-blazpays-spin-win-abc65990150e"
              }
            />
            <div className="flex sm:gap-x-10 gap-x-4">
              <JackpotButtonCard
                text="Current Spin Rewards"
                value={currentEarnings}
              />
              <JackpotButtonCard
                text="Total Spin Rewards"
                value={totalRewards}
              />
            </div>
            <FilledButton
              handleClick={handleBulkSpinSubmit}
              text={isSubmitting ? "Submitting..." : "Submit"}
              isLoading={isSubmitting}
              loadintText={"Submitting..."}
              disabled={isSubmitting || spinButton}
            />
            <span className="relative -top-3 text-[10px]">
              Submit Before Leaving the Page
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Spin;
