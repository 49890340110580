import React, { useState, useEffect } from "react";
import MainCard from "../components/dailyTasks/MainCard";
import UserLogos from "../components/dailyTasks/UserLogos";
import SocialCard from "../components/dailyTasks/SocialCard";
import TimerCard from "../components/dailyTasks/TimerCard";
import DotLoader from "../components/loaders/DotLoader";
import { useWallet } from "../hooks/useWallet";
import noTaskUrl from "../assets/icon 3-01.png";
import "./index.css";
import { useDailyTask } from "../hooks/useDailyTask";
const DailyTasks = () => {
  const [rewardCount, setRewardCount] = useState(0);
  const [totalReward, setTotalReward] = useState(0);
  const [taskData, setTaskData] = useState();
  const [timer, setTimer] = useState([
    { label: "days", count: 0 },
    { label: "hrs", count: 0 },
    { label: "mins", count: 0 },
    { label: "sec", count: 0 },
  ]);
  const [activeTask, setActiveTask] = useState(null);
  const [taskCompleted, setTaskCompleted] = useState(false);
  const [tasks, setTasks] = useState([]);
  const { wallet } = useWallet();
  const { data, isLoading, isError, refetch } = useDailyTask();

  const calculateTimeLeft = () => {
    const now = new Date();
    const currentUTC = Date.UTC(
      now.getUTCFullYear(),
      now.getUTCMonth(),
      now.getUTCDate(),
      now.getUTCHours(),
      now.getUTCMinutes(),
      now.getUTCSeconds()
    );
    const targetUTC = new Date();
    targetUTC.setUTCHours(23, 59, 59, 999);

    const timeDiff = targetUTC - currentUTC;
    if (timeDiff <= 0) {
      return { days: 0, hrs: 0, mins: 0, sec: 0 };
    }
    const sec = Math.floor((timeDiff / 1000) % 60);
    const mins = Math.floor((timeDiff / 1000 / 60) % 60);
    const hrs = Math.floor((timeDiff / 1000 / 60 / 60) % 24);
    const days = Math.floor(timeDiff / 1000 / 60 / 60 / 24);
    return { days, hrs, mins, sec };
  };
  useEffect(() => {
    const updateTimer = () => {
      const { days, hrs, mins, sec } = calculateTimeLeft();
      setTimer([
        { label: "days", count: days },
        { label: "hrs", count: hrs },
        { label: "mins", count: mins },
        { label: "sec", count: sec },
      ]);
    };
    updateTimer();
    const intervalId = setInterval(updateTimer, 1000);

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    if (!isLoading && data?.tasks?.length > 0) {
      setTasks(data?.tasks);
      setTaskData(data);
    }
  }, [data, taskCompleted]);

  useEffect(() => {
    let totalCount = 0;
    if (!isLoading && data?.tasks?.length > 0) {
      data?.tasks?.forEach((task) => {
        totalCount += task?.points;
      });
    }
    setTotalReward(totalCount);
  }, [data, isLoading]);
  useEffect(() => {
    let count = 0;
    if (!isLoading && data?.tasks?.length > 0) {
      data?.tasks?.forEach((task) => {
        if (task?.isCompleted) {
          count += task?.points;
        }
      });
    }
    setRewardCount(count);
  }, [tasks, data, isLoading, taskCompleted]);
  useEffect(() => {
    if (taskCompleted) {
      refetch();
    }
  }, [taskCompleted, refetch]);
  console.log("tasks", data);
  return (
    <div className="min-h-screen font-redHat w-full 2xl:pb-0 pb-4 2xl:px-5 xl:px-8 px-5 2xl:pt-28 sm:pt-24 flex  justify-center pt-20">
      <div className="lg:container w-full mx-auto">
        <MainCard />
        <div className="flex   w-full  mt-3 justify-end items-center">
          <UserLogos count={16000} />
        </div>
        <div className="flex  xl:-mt-2 md:mt-6 mt-16 flex-col w-full">
          {data?.tasks?.length === 0 ? (
            <>
              <div className="w-full flex flex-col items-center">
                <img src={noTaskUrl} />
                <h1 className="text-2xl relative sm:-left-11 -left-5 font-bold mt-3">
                  No task found
                </h1>
              </div>
            </>
          ) : (
            <>
              <div className="flex md:flex-row flex-col md:pl-0 sm:pl-6 md:items-center items-start relative top-1 md:gap-x-2">
                <h1 className="md:text-2xl sm:text-4xl text-2xl font-bold">
                  Tasks
                </h1>
                <p className="md:text-[14px] sm:text-xl text-xs text-[#666666]">
                  {taskData?.completed}/{taskData?.totalTasks} Completed
                </p>
              </div>
              <div className="flex relative w-full md:mt-4 mt-24 xl:gap-x-12 lg:gap-x-8 gap-x-6 items-start">
                <div className="flex lg:w-[66%] md:w-[70%] relative w-full flex-col xl:gap-y-2 gap-y-4">
                  <DotLoader
                    isLoading={isLoading}
                    className={"w-full flex items-center justify-center h-60 "}
                  />
                  {tasks?.map((social) => {
                    return (
                      <SocialCard
                        key={social?._id}
                        icon={social?.imageUrl}
                        title={social?.title}
                        points={social?.points}
                        id={social?._id}
                        activeId={activeTask}
                        desc={social?.description}
                        link={social?.redirectUrl}
                        isCompleted={social?.isCompleted}
                        setTaskCompleted={setTaskCompleted}
                        apiUrl={social?.apiUrl}
                        handleClick={() =>
                          setActiveTask((currentTask) =>
                            currentTask === social?._id ? null : social?._id
                          )
                        }
                      />
                    );
                  })}
                </div>
                <TimerCard
                  reward={rewardCount}
                  totalReward={totalReward}
                  className={
                    "md:relative absolute md:top-0 md:right-0 sm:right-6 right-0 sm:-top-52 -top-44"
                  }
                  timer={timer}
                />
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default DailyTasks;
