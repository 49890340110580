import React from "react";

const InputField = ({
  className,
  value,
  onChange,
  required,
  disabled,
  placeholder,
  type = "text",
  id,
  module = "create",
  logoState = false,
  logo = "",
}) => {
  return (
    <div className="w-full relative">
      {logoState && (
        <div className="absolute border-r border-r-[#444] left-2 top-1/2 -translate-y-1/2 px-2">
          {logo}
        </div>
      )}
      <input
        type={type}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        className={`${className} w-full bg-transparent outline-none focus:border-[#9a9a9a]
       transition-all duration-250 
       ${
         module === "create"
           ? "md:px-12 sm:px-8 px-4 2xl:py-5 xl:py-4 sm:rounded-2xl rounded-xl lg:py-3 py-[10px] 2xl:text-2xl lg:text-xl sm:text-base text-[13px]"
           : `2xl:text-base sm:text-[14px] text-xs xl:py-3 py-2 ${
               logoState === true
                 ? "xl:pl-[52px] pl-12 pr-3"
                 : "md:px-7 sm:px-5 px-4"
             }  2xl:rounded-3xl rounded-2xl`
       } border border-[#666666] `}
        required={required}
        disabled={disabled}
        id={id}
      />
    </div>
  );
};

export default InputField;
