import React from "react";
import blazPointsLogoURI from "../../assets/blaz_points.png";
const NavPointsCard = ({ points }) => {
  return (
    <div
      style={{
        boxShadow: "0 0 20px rgba(255,255,255,0.4) inset",
      }}
      className="md:flex hidden items-center lg:px-4 px-3 lg:py-1 lg:my-0 my-[6px] lg:gap-x-4 gap-x-2 bg-black rounded-full border-2 border-primary"
    >
      <img
        src={blazPointsLogoURI}
        alt="Fire"
        className="lg:scale-75 scale-[.70]"
      />
      <h1 className="font-redHat font-bold lg:text-xs text-[10px]">
        {points.toFixed(0)}
      </h1>
    </div>
  );
};
export default NavPointsCard;
