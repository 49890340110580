import React from "react";
import { MdTimelapse } from "react-icons/md";
import rewardsDiamond from "../../assets/reward_diamond.svg";
import rewardCoinLeft from "../../assets/reward_coin_left.svg";
import rewardCoinRight from "../../assets/reward_coin_right.svg";
const TimerCard = ({ timer, className, reward, totalReward }) => {
  return (
    <>
      <div
        className={`w-fit font-redHat  flex flex-col lg:gap-y-6 md:gap-y-4 gap-y-2 ${className}`}
      >
        <div className="lg:rounded-[27px] sm:rounded-3xl rounded-2xl bg-[#0b0b0b] border border-[#666666] lg:p-6 sm:p-5 p-3">
          <div className="flex gap-x-2 items-center">
            <MdTimelapse className="sm:text-2xl text-xl text-[#ff3503]" />
            <h1 className="sm:text-base text-xs">Quest ends in </h1>
          </div>
          <div className="flex lg:mt-4 mt-2 bg-black text-[#666666] items-center lg:max-w-[320px] sm:max-w-[280px] max-w-[240px] rounded-[10px] border border-[#666666]">
            {timer.map((item, index) => {
              return (
                <div
                  key={index}
                  className={`flex  flex-col text-center justify-center lg:h-20 h-12  border-[#666666] lg:w-20  w-12 ${
                    index === 0 ? "" : "border-l"
                  }`}
                >
                  <h1 className="lg:text-4xl lg:leading-[1.2] leading-[1.0] sm:text-xl text-base font-bold">
                    {item.count}
                  </h1>
                  <span className="text-[10px]  capitalize">{item.label}</span>
                </div>
              );
            })}
          </div>
        </div>
        <div className="flex group justify-between bg-[#0b0b0b] sm:rounded-2xl rounded-[12px] items-center lg:p-5 sm:p-4 p-2 border border-[#666666]">
          <div className="flex gap-x-1  lg:text-base md:text-[14px] text-xs items-center">
            <img
              src={rewardsDiamond}
              alt=""
              className="group-hover:scale-110 lg:scale-100 scale-90 transation-all duration-100 ease"
            />
            <span>Rewards</span>
          </div>
          <div className="flex gap-x-1 relative items-center">
            <img src={rewardCoinLeft} alt="" />
            <img
              src={rewardCoinRight}
              alt=""
              className="absolute sm:scale-100 scale-[.75] -top-1 left-2"
            />
            <p>
              {" "}
              {reward}/{totalReward} Blz
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default TimerCard;
