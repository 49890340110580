import React from "react";
import avatarLogo1 from "../../assets/avatar/avatar1.png";
import avatarLogo2 from "../../assets/avatar/avatar2.png";
import avatarLogo3 from "../../assets/avatar/avatar3.png";
import avatarLogo4 from "../../assets/avatar/avatar4.png";
import avatarLogo5 from "../../assets/avatar/avatar5.png";
const lgoos = [avatarLogo1, avatarLogo2, avatarLogo3, avatarLogo4, avatarLogo5];
const UserLogos = ({ count }) => {
  return (
    <div className="flex  relative items-center gap-x-2">
      {lgoos?.map((logo, index) => {
        return (
          <div key={index} className=" -left-12 absolute">
            <img
              style={{ left: `-${index * 12}px` }}
              src={logo}
              alt={logo}
              className="h-8 w-8 relative  rounded-full"
            />
          </div>
        );
      })}
      <span className="text-base font-redHat font-bold">
        {count >= 1000 ? <>{count / 10 ** 3}K</> : <>{count}</>}+
      </span>
    </div>
  );
};

export default UserLogos;
