import React, { useState, useEffect } from "react";
import { HiCheck } from "react-icons/hi";
import { BsFillArrowUpRightCircleFill } from "react-icons/bs";
import { MdOutlineArrowDropDown } from "react-icons/md";
import { IoMdRefresh } from "react-icons/io";
import axios from "axios";
import { toast } from "react-toastify";
const defaultTaskLogo =
  "https://pbs.twimg.com/profile_images/1777773865017417731/HFcDk0jg_400x400.jpg";
const SocialCard = ({
  icon,
  title,
  points,
  handleClick,
  id,
  activeId,
  desc,
  link,
  isCompleted,
  apiUrl,
  setTaskCompleted,
}) => {
  // const [hasRedirected, setHasRedirected] = useState(false);
  // const [status, setStatus] = useState(false);
  const [blazTaskStatus, setBlazTaskStatus] = useState(false);
  const handleSocialTask = async () => {
    try {
      const res = await axios.post(`/api/task/${id}/complete`);
      if (res?.status === 200) {
        setTaskCompleted((prev) => !prev);
      }
    } catch (err) {
      console.error(err);
    }
  };
  const handleDefiTask = async () => {
    try {
      const res = await axios.get(apiUrl);
      setBlazTaskStatus(res?.taskCompleted);
      if (!res?.taskCompleted) {
        toast.error(`${title} Task not completed yet!`);
      }
    } catch (err) {
      console.error(err);
    }
  };
  const handleRedirect = () => {
    setTaskCompleted(false);
    if (!apiUrl) {
      handleSocialTask();
    }
    window.open(link, "_blank");
    // const cardData = {
    //   status: false,
    //   redirectedTimestamp: Date.now(),
    // };
    // localStorage.setItem(`card-${id}`, JSON.stringify(cardData));
    // setTimeout(() => applyChanges(), 10000);
  };

  // useEffect(() => {
  //   const cardData = JSON.parse(localStorage.getItem(`card-${id}`)) || {};
  //   const { status: storedChangesMade, redirectedTimestamp } = cardData;

  //   if (storedChangesMade) {
  //     setStatus(true);
  //   } else if (redirectedTimestamp) {
  //     const elapsedTime = Date.now() - parseInt(redirectedTimestamp, 10);
  //     if (elapsedTime >= 10000) {
  //       applyChanges();
  //     } else {
  //       const remainingTime = 10000 - elapsedTime;
  //       setTimeout(() => applyChanges(), remainingTime);
  //     }
  //   }
  // }, [id]);
  // const applyChanges = () => {
  //   setHasRedirected(true);
  //   setStatus(true);
  //   const cardData = {
  //     status: true,
  //     redirectedTimestamp: Date.now(),
  //   };
  //   localStorage.setItem(`card-${id}`, JSON.stringify(cardData));
  // };
  useEffect(() => {
    if (blazTaskStatus) {
      setTaskCompleted(true);
    }
  }, [blazTaskStatus]);
  return (
    <div
      onClick={() => {
        if (!isCompleted) {
          handleRedirect();
        }
      }}
      className={`w-full  transition-all  cursor-pointer duration-[400ms] hover:border-2 hover:border-[#2eee0f] ease-in-out lg:rounded-[20px] rounded-[18px] group ${
        !isCompleted && "border"
      } border-[#666666] ${
        activeId === id ? "h-fit" : "h-16"
      } transition-h duration-250 ease-in-out relative`}
      style={{
        background: `${
          isCompleted ? "linear-gradient(to right,#666666,#2eee0f)" : "#141419"
        }`,
      }}
    >
      <div
        className={`${
          activeId !== id && "absolute"
        }} transition-all duration-[300ms] ease-in-out h-[calc(100%-4px)] w-[calc(100%-4px)] xl:py-4 lg:py-2 py-4  lg:px-8 px-5  mt-[2px] ml-[2px]  bg-[#141419] flex flex-col items-start  lg:rounded-[18px] rounded-2xl`}
      >
        <div className="w-full flex justify-between items-center">
          <div className="flex lg:gap-x-8 sm:gap-x-4 gap-x-2 items-center">
            <div className="flex items-center gap-x-2">
              <div>
                <MdOutlineArrowDropDown
                  onClick={(e) => {
                    e.stopPropagation();
                    handleClick();
                  }}
                  className={`text-3xl hover:opacity-75 ${
                    activeId === id ? "rotate-0" : "-rotate-90"
                  } cursor-pointer transition-all duration-[300ms] ease-in-out`}
                />
              </div>
              <img
                src={icon === "" ? defaultTaskLogo : icon}
                className="2xl:h-8 h-6 2xl:w-8 w-6 rounded-md"
                alt={icon}
              />
            </div>
            <h1 className="font-bold font-akira lg:text-base sm:text-[13px] text-[10px]">
              {title}
            </h1>
          </div>
          <div className="flex items-center lg:gap-x-6 sm:gap-x-3 gap-x-1 ">
            <span className="text-[#666666] text-center sm:text-xs text-[8px]">
              {points} BLZ Points
            </span>
            {isCompleted ? (
              <div className="sm:h-6 h-4 sm:w-6 h-4 rounded-full flex items-center justify-center border-[2.5px] border-[#2eee0f]">
                <HiCheck className="sm:text-base text-xs font-bold text-[#2eee0f]" />
              </div>
            ) : (
              <>
                {apiUrl ? (
                  <div className="flex justify-center items-center gap-x-1">
                    {!blazTaskStatus && (
                      <IoMdRefresh
                        onClick={(e) => {
                          e.stopPropagation();
                          handleDefiTask();
                        }}
                        className="sm:text-3xl text-[#bbb] hover:opacity-70 hover:scale-[.94] active:rotate-360 transition-all duration-150 text-xl rotate-180 font-bold cursor-pointer"
                      />
                    )}
                    <BsFillArrowUpRightCircleFill
                      onClick={(e) => {
                        e.stopPropagation();
                        handleRedirect();
                      }}
                      className="sm:text-2xl text-xl  font-bold cursor-pointer"
                    />
                  </div>
                ) : (
                  <BsFillArrowUpRightCircleFill
                    onClick={(e) => {
                      e.stopPropagation();
                      handleRedirect();
                    }}
                    className="sm:text-2xl text-xl  font-bold cursor-pointer"
                  />
                )}
              </>
            )}
          </div>
        </div>
        {activeId === id && <p className="w-full my-4">{desc}</p>}
      </div>
    </div>
  );
};

export default SocialCard;
