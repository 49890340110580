import { useQuery } from "@tanstack/react-query";
import { getDailyTasks } from "../api/misc";
import { useWallet } from "./useWallet";

export const useDailyTask = (taskCompleted) => {
  const wallet = useWallet();

  const { data, isLoading, isError, refetch } = useQuery({
    queryKey: ["dailyTask", wallet?.chainId, taskCompleted],
    queryFn: () => getDailyTasks(),
    enabled: !!wallet?.chainId,
    staleTime: Infinity,
    cacheTime: Infinity,
  });

  return { data, isLoading, isError, refetch };
};
