import React, { useMemo, useState, useRef, useEffect } from "react";
import Logo from "../../assets/logo_blazpay.png";
import { Link, useNavigate } from "react-router-dom";
import NavPointsCard from "../../components/navbar/NavPointsCard";
import NavAssetCard from "../../components/navbar/NavAssetCard";
import NavUserButton from "../../components/navbar/NavUserButton";
import { useAuth } from "../../hooks/useAuth";
import { chains } from "../../services/chains";
import { useWallet } from "../../hooks/useWallet";
import { useWeb3Modal } from "@web3modal/ethers5/react";
import { FaRegUser } from "react-icons/fa";
import { GrTransaction } from "react-icons/gr";
import { BiLogOutCircle } from "react-icons/bi";
import { HiOutlineRectangleGroup } from "react-icons/hi2";
import { HiTemplate } from "react-icons/hi";
import HamburgerMenu from "./HamburgerMenu";
const navMenuData = [
  {
    link: "/user/profile",
    text: "my profile",
    icon: <FaRegUser className="text-[14px]" />,
  },
  {
    link: "/user/items",
    text: "my items",
    icon: <HiTemplate className="text-[14px]" />,
  },
  {
    link: "/user/pass",
    text: "my entrypasses",
    icon: <HiOutlineRectangleGroup className="text-base" />,
  },
  {
    link: "/user/transactions",
    text: "recent transactions",
    icon: <GrTransaction className="text-[14px]" />,
  },
  {
    link: "",
    text: "logout",
    icon: <BiLogOutCircle className="text-base" />,
  },
];

const NavMenu = ({
  className,
  isActive,
  setIsActive,
  data,
  handleClick,
  handleLogout,
}) => {
  const menuRef = useRef(null);
  useEffect(() => {
    const handleClickOutsideMenu = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsActive(false);
      }
    };
    window.addEventListener("mousedown", handleClickOutsideMenu);
    return () =>
      window.removeEventListener("mousedown", handleClickOutsideMenu);
  }, []);
  return (
    <div
      ref={menuRef}
      className={`${className} ${
        isActive ? "opacity-100 scale-100 mt-0" : "opacity-0 scale-0 -mt-16"
      } transtion-all z-50 duration-200 ease-in rounded-3xl bg-black border p-2`}
    >
      <ul
        style={{
          background:
            "linear-gradient(-90deg,rgba(175, 162, 159, 0.31),rgba(17, 13, 10, 0.31),rgba(17, 13, 10, 0.31),rgba(220, 220, 220, 0.31)",
        }}
        className="rounded-2xl py-2"
      >
        {data.map((item) => {
          return (
            <li
              key={item.text}
              onClick={
                item.text === "logout"
                  ? handleLogout
                  : () => handleClick(item.link)
              }
              className="flex h-full capitalize w-full hover:text-opacity-70 items-center gap-x-2 pl-6 pr-12 py-1 cursor-pointer font-bold font-redHat text-xs text-white"
            >
              {item.icon}
              <span>{item.text}</span>
            </li>
          );
        })}
      </ul>
    </div>
  );
};
const Navbar = () => {
  const auth = useAuth();
  const [click, setClick] = useState(false);
  const [notif, setNotif] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const wallet = useWallet();
  const navigate = useNavigate();
  const { open } = useWeb3Modal();
  const toggle = () => {
    setIsExpanded((prev) => !prev);
  };
  const handleLogout = (e) => {
    e.preventDefault();
    auth.logout();
  };

  const user = auth.user;
  const username = user.username;

  const chain = useMemo(
    () => chains.find((item) => item.id == wallet.chainId),
    [wallet.chainId]
  );

  const closeProfile = (link) => {
    setClick(!click);
    navigate(link);
  };

  const handleNotification = () => {
    setNotif(!notif);
  };
  const navLinks = [
    { value: "home", link: "dashboard" },
    { value: "earn", link: "gaming-marketplace" },
    { value: "leaderboard", link: "leaderboard" },
    { value: "referral", link: "refer" },
    { value: "calculator", link: "calculator" },
  ];
  return (
    <nav
      style={{
        backdropFilter: "blur(8px)",
      }}
      className="flex md:border border-primary fixed md:py-0 py-2 lg:top-4 md:top-6 lg:container md:w-[calc(100%-32px)] w-full lg:px-8 md:px-3 sm:px-7 px-5 left-1/2 -translate-x-1/2 md:bg-black bg-[rgba(0,0,0,0.24)] md:rounded-full items-center justify-between  z-[100]"
    >
      <Link to="/" className="">
        <img
          src={Logo}
          alt="Blazpay logo"
          className="w-32 md:w-24  lg:w-32 w-28 relative sm:left-0 -left-4"
        />
      </Link>
      <ul
        className={`flex md:flex-row flex-col ${
          isExpanded ? "flex " : "md:left-0 left-[-100%]"
        } md:gap-y-0 gap-y-12 transition-all duration-[400ms]  ease-out md:border-0 border-t border-t-primary md:relative  absolute md:top-0 top-16 md:w-fit w-full md:py-0 py-12 bg-black left-0 items-center xl:gap-x-7  md:gap-x-5  font-akira  xl:text-[13px] md:text-[8px] text-xs text-white`}
      >
        {navLinks.map((item, index) => {
          return (
            <li
              key={index}
              onClick={() => {
                navigate(item.link);
                setIsExpanded((x) => !x);
              }}
              className="cursor-pointer hover:opacity-75"
            >
              {item.value}
            </li>
          );
        })}
        <li className="md:hidden w-fit border border-primary px-8 py-2 rounded-full">
          Points :{" "}
          <span className="text-[#ff3503]">{auth.tokens} &nbsp;Blz</span>
        </li>
      </ul>
      <div className="flex lg:gap-x-4 gap-x-2 flex relative items-center ">
        <NavPointsCard points={auth.tokens} />
        <NavAssetCard
          handleClick={() =>
            open({ view: wallet.isConnected ? "Networks" : "Connect" })
          }
          logoURI={chain?.tokenImg}
          chain={chain}
        />
        <NavUserButton username={username} handleClick={closeProfile} />
        <HamburgerMenu
          handleClick={toggle}
          isExpanded={isExpanded}
          className={"md:hidden block"}
        />

        <NavMenu
          data={navMenuData}
          isActive={click}
          setIsActive={setClick}
          className="absolute -right-2 top-[50px]"
          handleClick={closeProfile}
          handleLogout={handleLogout}
        />
      </div>
    </nav>
  );
};

export default Navbar;
